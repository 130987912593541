import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'classnames';
import OutsideClick from '../outSideClick';
import DropDownMenu from '../dropdownMenu';
import { Variant } from '../dropdownMenu/types';
import { Props, ShowValues } from '../multiSelectDropdown/type';
import { ReactComponent as DownIcon } from 'assets/img/arrowDownAlt.svg';

import styles from './MultiSelectDropDown.module.scss';

const MultiSelectDropDown = ({
  variants,
  disabled,
  takeValues,
  className,
  menuContainerClassname,
  ...rest
}: Props): JSX.Element => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [chosenValues, setChosenValues] = useState<Variant[]>([]);
  const [showValues, setShowValues] = useState<ShowValues>({
    inputValue: '',
    inputCount: 0,
  });

  const containerStyle = clsx(styles.container, {
    [`${className}`]: !!className,
  });

  const multiSelectDropDownStyle = clsx(styles.multiSelectDropDownWrapper, {
    [styles.focused]: openDropdown,
    [styles.disabled]: disabled,
  });

  const inputValue = useMemo(() => {
    if (showValues.inputValue.length >= 55) {
      return showValues.inputValue.slice(0, 55) + '...';
    }
    return showValues.inputValue;
  }, [showValues]);

  const openDropDown = () => {
    if (!disabled) {
      setOpenDropdown(!openDropdown);
    }
  };

  const chooseValue = useCallback(
    (arg: Variant) => {
      let inputValue = '';
      let inputCount = 0;
      const res = chosenValues.map((value: Variant) => {
        if (value.id === arg.id) {
          value.selected = !value.selected;
        }

        if (value.selected) {
          if (inputValue) {
            inputValue += ', ';
          }
          inputValue += value.name;
          inputCount++;
          setShowValues({
            inputValue,
            inputCount,
          });
        }
        if (!inputValue) {
          setShowValues({ inputValue, inputCount });
        }

        return value;
      });

      if (res) {
        setChosenValues(res);
        takeValues(chosenValues.filter(({ selected }) => selected));
      }
    },
    [chosenValues, takeValues],
  );

  // init internal state from passed values
  useEffect(() => {
    setChosenValues(variants);

    setShowValues({
      inputValue: variants
        .filter(({ selected }) => selected)
        .map(({ name }) => name)
        .join(', '),
      inputCount: variants.filter(({ selected }) => selected).length,
    });
  }, [variants]);

  return (
    <div className={containerStyle} {...rest}>
      <OutsideClick onOutsideClick={() => setOpenDropdown(false)}>
        <div className={styles.container} onClick={openDropDown}>
          <div className={multiSelectDropDownStyle}>
            <div className={styles.inputContainer}>
              <p>{inputValue}</p>
              <div>{showValues.inputCount}</div>
            </div>
            <div className={styles.downIcon}>
              <DownIcon />
            </div>
          </div>
        </div>
        {!disabled && variants.length > 0 && (
          <div className={styles.menu}>
            <DropDownMenu
              open={openDropdown}
              multiSelect={true}
              variants={chosenValues}
              chooseValue={chooseValue}
              containerClassname={menuContainerClassname}
            />
          </div>
        )}
      </OutsideClick>
    </div>
  );
};

export default MultiSelectDropDown;
