import React, { createContext, useCallback, useEffect } from 'react';
import { Whitelabel } from 'types/types';
import { useDispatch } from 'react-redux';
import { whitelabelSet } from 'slices/authSlice';
import { getWhitelabelByDomain } from 'services/whitelabel.service';

const { REACT_APP_WHITELABEL_SUFFIX: suffix } = process.env;

export const WhitelabelContext = createContext({});

/**
 * Fetches current provider based on the URL and updates page attributes
 */
const WhitelabelProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();

  const updateAttributes = useCallback((whitelabel: Whitelabel) => {
    // update favicon
    if (!!whitelabel?.faviconContent?.length) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link.href = whitelabel.faviconContent;
    }

    // update meta description
    const metaDescriptionElement = document.querySelector("meta[name~='description']");
    if (metaDescriptionElement && !!whitelabel.metaDescription?.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      metaDescriptionElement.content = whitelabel.metaDescription;
    }

    // update title
    const titleElement = document.querySelector('title');
    if (titleElement && !!whitelabel.name?.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      titleElement.text = whitelabel.name || '';
    }
  }, []);

  useEffect(() => {
    const hostname = window.location.hostname;
    // const hostname = 'rekall-dev.paintgun.io';
    const domain = suffix && hostname.endsWith(suffix) ? hostname.replace(suffix, '') : undefined;
    if (domain) {
      getWhitelabelByDomain(domain).then((result) => {
        if (result) {
          dispatch(whitelabelSet(result));
          updateAttributes(result);
        }
      });
    }
  }, [dispatch, updateAttributes]);

  const value = {};

  return <WhitelabelContext.Provider value={value}>{children}</WhitelabelContext.Provider>;
};

export default WhitelabelProvider;
