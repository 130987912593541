import React from 'react';
import clsx from 'classnames';
import { Props } from './type';
import styles from './Button.module.scss';

const Button = ({
  size = 'normal',
  children,
  className,
  icon,
  alignIcon,
  buttonType = 'primary',
  ...rest
}: Props): JSX.Element => {
  const alignIconStyle = clsx(styles.iconContainer, {
    [styles.iconReverseContainer]: alignIcon === 'right',
  });

  const buttonClass = clsx(styles.button, {
    [`${className}`]: !!className,
    [styles.buttonSmallSize]: size === 'small',
    [styles.buttonNormalSize]: size === 'normal',
    [styles.buttonLargeSize]: size === 'large',
    [styles.primaryButton]: buttonType === 'primary',
    [styles.secondaryButton]: buttonType === 'secondary',
    [styles.tertiaryButton]: buttonType === 'tertiary',
  });

  return (
    <button className={buttonClass} {...rest}>
      <div className={alignIconStyle}>
        {icon}
        {icon && <span className={styles.spacer} />}
        {children}
      </div>
    </button>
  );
};

export default Button;
