/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo } from 'react';
import ButtonsKeyCodes from 'utils/buttonsKeyCodes';

interface KeyCodeActions {
  [keyCode: string | number]: () => void;
}

const useKeydownListener = (codeActions: KeyCodeActions, isIgnore?: boolean) => {
  const keyCodeActions = useMemo(() => {
    const res = {} as KeyCodeActions;

    Object.keys(codeActions).forEach((code) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const keyCode = Object.keys(ButtonsKeyCodes).includes(code) ? ButtonsKeyCodes[code] : code;
      res[keyCode] = codeActions[code];
    });
    return res;
  }, [codeActions]);

  const handleKeyPress = useCallback(
    (event: any) => !isIgnore && typeof keyCodeActions[event.keyCode] === 'function' && keyCodeActions[event.keyCode](),
    [isIgnore, keyCodeActions],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false);

    return () => document.removeEventListener('keydown', handleKeyPress, false);
  }, [handleKeyPress]);
};

export default useKeydownListener;
