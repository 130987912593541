import { gql } from '@apollo/client';
import { fragments } from 'graphql/queries';

export const auditedCreativeFragments = {
  auditedCreatives: gql`
    fragment AuditedCreatives on AuditedCreatives {
      items {
        ...AuditedCreative
      }
      paging {
        ...Paging
      }
    }
  `,
  auditedCreative: gql`
    fragment AuditedCreative on AuditedCreative {
      id

      # *************** SENSOR TOWER ***************
      stAdUnitId
      stAppId
      stCreativeId
      stNetwork
      stPhashionGroup
      stAdType
      stFirstSeen
      stLastSeen
      stCreativeUrl
      stPreviewUrl
      stThumbUrl
      stWidth
      stHeight
      stTitle
      stMessage
      stVideoDuration
      stShare

      # *************** MAIN ***************
      authorId
      ugc

      # *************** AD CONCEPT ***************
      memeTrend
      aiContent
      popularFormats
      popularFormatsFirstSecondDifferent
      popularFormatsFirstSecondDifferentPopularFormats

      # *************** ASSET DETAILS ***************
      layout
      singleAssetFormatType
      singleAssetType
      singleAssetSubType
      assetDetailsFirstSecondDifferent
      assetDetailsFirstSecondDifferentAssetType
      assetDetailsFirstSecondDifferentAssetSubType
      assetFormat
      assetRatio
      videoLength

      # *************** CONTENT ***************
      entertaining
      educating
      educatingApp
      educatingAppWholeApp
      educatingAppService
      educatingBroadTopic
      contentFirstSecondDifferent
      contentFirstSecondDifferentEntertaining
      contentFirstSecondDifferentEducating
      contentFirstSecondDifferentEducatingApp
      contentFirstSecondDifferentEducatingAppWholeApp
      contentFirstSecondDifferentEducatingAppService
      contentFirstSecondDifferentEducatingBroadTopic
      trend
      trendFirstSecondDifferent
      trendFirstSecondDifferentTrend
      references
      sound
      soundFirstSecondDifferent
      soundFirstSecondDifferentSound
      contentType
      contentTypeFirstSecondDifferent
      contentTypeFirstSecondDifferentContentType
      financialBenefit

      # *************** VISUAL FEATURES ***************
      mainAd
      mainAdFirstSecondDifferent
      mainAdFirstSecondDifferentVisualFeatures
      endCard
      ctaPlacement

      # *************** SCRIPT/COPY ***************
      subtitles
      subtitlesFirstSecondDifferent
      subtitlesFirstSecondDifferentSubtitles
      languages
      copyFeatures
    }
  `,
};

export const getAuditedCreatives = gql`
  query ($paging: SearchPaging!, $sorting: Sorting, $searchCriteria: AuditedCreativeSearchCriteria) {
    getAuditedCreatives(paging: $paging, sorting: $sorting, searchCriteria: $searchCriteria) {
      ...AuditedCreatives
    }
  }
  ${auditedCreativeFragments.auditedCreatives}
  ${auditedCreativeFragments.auditedCreative}
  ${fragments.paging}
`;
