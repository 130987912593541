import React, { useCallback, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Modal } from 'antd';
import { useMutation } from '@apollo/client';
import { Form, Formik, FormikHelpers } from 'formik';
import { ProjectInput } from 'types/inputTypes';
import { Project } from 'types/businessTypes';
import { upsertProject } from 'graphql/businessMutations';
import { Button, Checkbox, Input } from 'components/common';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { projectTitleMaxLength } from 'utils/businessUtils';
import logger from 'utils/logger/logger';
import { iso8601DateFormat } from 'utils/dateTimeUtils';
import moment from 'moment';
import DatePicker from 'components/common/datePicker';
import { ReactComponent as DeleteIcon } from 'assets/img/delete.svg';
import ToasterInfo from 'components/common/toasterInfo';
import { ClientPaymentModel } from 'types/types.d';

import styles from './UpdateProjectModal.module.scss';
import { getContactUsMessage } from 'utils/i18nUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean, existingNames: string[]): any | (() => any) => {
  return Yup.object().shape({
    title: validateOnSubmit
      ? Yup.string()
          .max(projectTitleMaxLength, t('VALIDATION.PROJECT_TITLE_MAX_LENGTH', { length: projectTitleMaxLength }))
          .test('name-test', t('VALIDATION.TITLE_TAKEN'), (value) => {
            return !existingNames.find((i) => i === value);
          })
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .max(projectTitleMaxLength, t('VALIDATION.PROJECT_TITLE_MAX_LENGTH', { length: projectTitleMaxLength }))
          .optional(),
    deliveryDate: validateOnSubmit ? Yup.string().required(t('VALIDATION.REQUIRED')) : Yup.string().optional(),
  });
};

interface Values {
  title: string;
  deliveryDate: string;
  ugc: boolean;
}

interface Props {
  projects: Project[];
  project: Project;
  onHide: () => void;
  onDone: () => void;
  onDelete: () => void;
}

export default function UpdateProjectModal({ projects, project, onHide, onDone, onDelete }: Props): JSX.Element {
  const { t } = useTranslation();

  const [doUpdate] = useMutation<{ upsertProject?: Project }>(upsertProject);

  const [submitTriggered, setSubmitTriggered] = useState(false);

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const handleSubmit = useCallback(
    ({ title, deliveryDate, ugc }: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
      setSubmitting(true);

      doUpdate({
        variables: {
          input: {
            clientId: project.clientId,
            id: project.id,
            paymentModel: project.paymentModel,
            title,
            deliveryDate,
            ugc: project.paymentModel === ClientPaymentModel.STANDARD ? ugc : null,
            briefId: project.briefId,
          } as ProjectInput,
        },
      })
        .then((result) => {
          if (result?.data) {
            showSuccessNotification(t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.UPDATE_SUCCESS'));
            onDone();
          } else {
            logger.error('No result for project update');
            showErrorNotification();
            setSubmitting(false);
          }
        })
        .catch((error) => {
          logger.error(`Update project error: ${error}`);
          showErrorNotification();
          setSubmitting(false);
        });
    },
    [
      doUpdate,
      onDone,
      project.briefId,
      project.clientId,
      project.id,
      project.paymentModel,
      showErrorNotification,
      showSuccessNotification,
      t,
    ],
  );

  return (
    <Modal open={true} onCancel={onHide} className={styles.modal} footer={null} maskClosable={false}>
      <div className={styles.modalHeader}>
        <div className={styles.title}>{t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.UPDATE_TITLE')}</div>
      </div>
      <div className={styles.body}>
        <Formik
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          validationSchema={validationSchema(
            t,
            submitTriggered,
            projects.filter((i) => i.id !== project.id).map((i) => i.title),
          )}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{
            title: project.title,
            deliveryDate: project.deliveryDate,
            ugc: project.ugc || false,
          }}
        >
          {({ handleChange, handleBlur, values, errors, isSubmitting, setFieldValue }) => (
            <Form noValidate className={styles.form}>
              <div className={styles.formItem}>
                <div className={styles.formItemLabel}>{t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.TITLE')}</div>
                <Input
                  type="title"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  error={!!errors.title?.length ? errors.title : undefined}
                />
              </div>
              <div className={styles.formItem}>
                <div className={styles.formItemLabel}>{t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.DELIVERY_DATE')}</div>
                <DatePicker
                  date={values.deliveryDate}
                  returnFormat={iso8601DateFormat}
                  onChange={(value) => {
                    setFieldValue('deliveryDate', moment(value).format(iso8601DateFormat), true);
                  }}
                />
              </div>
              {project.paymentModel === ClientPaymentModel.STANDARD && (
                <div className={styles.formItem}>
                  <div className={styles.formItemLabel}>{t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.UGC')}</div>
                  <Checkbox
                    className={styles.ugc}
                    label={t('COMMON.YES')}
                    labelAfter={true}
                    name="ugc"
                    onBlur={handleBlur}
                    checked={values.ugc}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className={styles.buttons}>
                <Button buttonType="tertiary" icon={<DeleteIcon />} onClick={onDelete}>
                  {t('COMMON.DELETE')}
                </Button>
                <div className={styles.buttonsMain}>
                  <Button className={styles.button} buttonType="secondary" disabled={isSubmitting} onClick={onHide}>
                    {t('COMMON.CANCEL')}
                  </Button>
                  <Button
                    className={styles.button}
                    buttonType="primary"
                    type="submit"
                    disabled={!values.title.length || !!errors.title || isSubmitting}
                    onClick={() => setSubmitTriggered(true)}
                  >
                    {t('COMMON.UPDATE')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
