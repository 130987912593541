import React from 'react';
import clsx from 'classnames';
import { Props } from './type';

import styles from './Paragraph.module.scss';

const Paragraph = ({ size = 'large', className, children, ...rest }: Props): JSX.Element => {
  const paragraphStyle = clsx({
    [`${className}`]: !!className,
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
  });

  return (
    <p {...rest} className={paragraphStyle}>
      {children}
    </p>
  );
};

export default Paragraph;
