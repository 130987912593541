import React from 'react';
import { IKImage, IKVideo } from 'imagekitio-react';
import logger from 'utils/logger/logger';
import { getAdTypeByImageKitType } from 'utils/businessUtils';
import { AdType } from 'types/businessTypes.d';
import { Input } from 'components/common';
import { DDType } from 'components/features/private/dashboard/createAd/createAdModal/DraggableItem';
import { ReactComponent as CloseIcon } from 'assets/img/close.svg';
import { ReactComponent as DragIcon } from 'assets/img/drag.svg';
import clsx from 'classnames';

import styles from './CreateAddModalWithSizes.module.scss';

interface Props {
  index: number;
  mediaName: string;
  mediaType: string;
  mediaSize: number;
  mediaFilePath: string;
  withDrag: boolean;
  onNameChange: (value: string) => void;
  onDelete: (index: number) => void;
  type?: DDType.DRAG_TYPE | DDType.DROP_TYPE;
}

export default function ContentItem({
  index,
  mediaName,
  mediaType,
  mediaSize,
  mediaFilePath,
  withDrag,
  onNameChange,
  onDelete,
  type,
}: Props): JSX.Element {
  return !type || type === DDType.DRAG_TYPE ? (
    <div className={styles.media}>
      {withDrag && (
        <div className={styles.drag}>
          <DragIcon />
        </div>
      )}
      <div className={styles.image}>
        {getAdTypeByImageKitType(mediaType) === AdType.SINGLE_IMAGE ? (
          <IKImage
            path={mediaFilePath}
            onError={(e) => logger.error(e)}
            transformation={[{ height: '112', width: '112' }]}
          />
        ) : (
          <IKVideo
            path={mediaFilePath}
            onError={(e) => logger.error(e)}
            transformation={[{ height: '112', width: '112' }]}
          />
        )}
      </div>
      <div className={clsx(styles.details, { [styles.detailsWithDrag]: withDrag })}>
        <Input
          value={mediaName}
          onChange={(e) => {
            const value = e.target.value;
            if (!!value?.length) onNameChange(value);
          }}
        />
        <div className={styles.actions}>
          <div className={styles.size}>
            {mediaSize < 1000000 ? `${(mediaSize / 1000).toFixed()} Kb` : `${(mediaSize / 1000000).toFixed(2)} Mb`}
          </div>
          <div className={styles.delete} onClick={() => onDelete(index)}>
            <CloseIcon />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.dropSection} />
  );
}
