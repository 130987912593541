import React, { ChangeEvent, useState } from 'react';
import clsx from 'classnames';
import { Props } from './type';

import styles from './Textarea.module.scss';

const Textarea = ({ disabled, onChange, style, className, maxLength = 200, ...rest }: Props): JSX.Element => {
  const [value, setValue] = useState<string>();

  const onValueChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e);
    }
    setValue(e.target.value);
  };

  const containerStyle = clsx(styles.container, {
    [`${className}`]: !!className,
  });

  const textareaWrapperStyle = clsx(styles.textareaWrapper, {
    [styles.disabled]: disabled,
  });

  return (
    <div className={containerStyle} style={style}>
      <textarea {...rest} maxLength={maxLength} className={textareaWrapperStyle} onChange={onValueChange} />
      <p>
        {value?.length || 0}/{value ? maxLength - value.length : maxLength}
      </p>
    </div>
  );
};

export default Textarea;
