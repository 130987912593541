import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'classnames';
import { logout } from 'services/auth.service';
import { Dropdown, Paragraph } from 'components/common';
import { ReactComponent as ChevronIcon } from 'assets/img/chevron.svg';
import { ReactComponent as WhiteChevronIcon } from 'assets/img/whiteChevron.svg';
import { RESET, useTrackedSelector } from 'components/app/store';
import { SecurityRole } from 'types/types.d';
import styles from './LoggedInFrame.module.scss';
import {
  ADMIN_CLIENTS,
  ADMIN_INVITATIONS,
  ADMIN_SETTINGS,
  ADMIN_USERS,
  ADMIN_WHITELABELS,
  ANALYTICS,
  AUDIT,
  DASHBOARD,
  DATA,
  SETTINGS,
  TEAM,
} from 'utils/routingUtils';
import { Props } from './type';
import { userHasAdminRole, userHasSession } from 'utils/userUtils';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { buildAdsDnaUrl } from 'components/features/private/adsDna/AdsDnaProvider';
import { buildDashboardUrl } from 'components/features/private/dashboard/DashboardProvider';
import { getLogoBlack, getLogoWhite } from 'utils/styleUtils';
import { buildAnalyticsUrl } from 'components/features/private/analytics/AnalyticsProvider';
import { buildAuditUrl } from 'components/features/private/audit/AuditProvider';

const LoggedInFrame = ({ children, color = 'white' }: Props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    pathname,
    searchParams: { [SearchParams.CLIENT_ID]: _clientId = '', [SearchParams.PROJECT_ID]: _projectId = '' },
  } = useCustomLocation();

  const {
    auth: { user, whitelabel },
  } = useTrackedSelector();

  const finalWhitelabel = useMemo(() => whitelabel?.id, [whitelabel?.id]);

  const navBarStyle = useMemo(
    () =>
      clsx(styles.navBar, {
        [styles.white]: color === 'white',
        [styles.black]: color === 'black',
      }),
    [color],
  );

  // const notificationBackground = useMemo(
  //   () =>
  //     clsx(styles.notificationBackground, {
  //       [styles.notificationWhite]: color === 'white',
  //       [styles.notificationBlack]: color === 'black',
  //     }),
  //   [color],
  // );

  const hasSession = useMemo(() => userHasSession(user), [user]);

  // bad but needed workaround to allow Current client to see ads dna before other clients
  const isCurrentUser = useMemo(
    () => user?.email.toLowerCase().includes('current.com') || user?.email.toLowerCase().includes('mobilegroove.com'),
    [user?.email],
  );

  const menu = useMemo(() => {
    switch (user?.role) {
      case SecurityRole.ADMIN:
        return !finalWhitelabel?.length
          ? [
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.CLIENTS'), route: ADMIN_CLIENTS },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.USERS'), route: ADMIN_USERS },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.INVITATIONS'), route: ADMIN_INVITATIONS },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.WHITELABELS'), route: ADMIN_WHITELABELS },
            ]
          : [
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.CLIENTS'), route: ADMIN_CLIENTS },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.USERS'), route: ADMIN_USERS },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.INVITATIONS'), route: ADMIN_INVITATIONS },
            ];
      case SecurityRole.PG_USER:
        return [
          {
            label: t('PRIVATE.LOGGED_IN_FRAME.MENU.DASHBOARD'),
            route: DASHBOARD,
            onNavigate: () =>
              navigate(
                buildDashboardUrl({
                  [SearchParams.CLIENT_ID]: _clientId,
                  [SearchParams.PROJECT_ID]: _projectId,
                }),
              ),
          },
          {
            label: t('PRIVATE.LOGGED_IN_FRAME.MENU.DATA'),
            route: DATA,
            onNavigate: () =>
              navigate(
                buildAdsDnaUrl({
                  [SearchParams.CLIENT_ID]: _clientId,
                  [SearchParams.PROJECT_ID]: _projectId,
                }),
              ),
          },
          {
            label: t('PRIVATE.LOGGED_IN_FRAME.MENU.ANALYTICS'),
            route: ANALYTICS,
            onNavigate: () =>
              navigate(
                buildAnalyticsUrl({
                  [SearchParams.CLIENT_ID]: _clientId,
                  [SearchParams.PROJECT_ID]: _projectId,
                }),
              ),
          },
          {
            label: t('PRIVATE.LOGGED_IN_FRAME.MENU.AUDIT'),
            route: AUDIT,
            onNavigate: () => navigate(buildAuditUrl({})),
          },
        ];
      case SecurityRole.CREATOR:
        return [{ label: t('PRIVATE.LOGGED_IN_FRAME.MENU.DASHBOARD'), route: DASHBOARD }];
      case SecurityRole.CLIENT_USER:
        return isCurrentUser
          ? [
              {
                label: t('PRIVATE.LOGGED_IN_FRAME.MENU.DASHBOARD'),
                route: DASHBOARD,
                onNavigate: () =>
                  navigate(
                    buildDashboardUrl({
                      [SearchParams.CLIENT_ID]: _clientId,
                      [SearchParams.PROJECT_ID]: _projectId,
                    }),
                  ),
              },
              {
                label: t('PRIVATE.LOGGED_IN_FRAME.MENU.DATA'),
                route: DATA,
                onNavigate: () =>
                  navigate(
                    buildAdsDnaUrl({
                      [SearchParams.CLIENT_ID]: _clientId,
                      [SearchParams.PROJECT_ID]: _projectId,
                    }),
                  ),
              },
              // {
              //   label: t('PRIVATE.LOGGED_IN_FRAME.MENU.ANALYTICS'),
              //   route: ANALYTICS,
              //   onNavigate: () =>
              //     navigate(
              //       buildAnalyticsUrl({
              //         [SearchParams.CLIENT_ID]: _clientId,
              //         [SearchParams.PROJECT_ID]: _projectId,
              //       }),
              //     ),
              // },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.TEAM'), route: TEAM },
            ]
          : [
              {
                label: t('PRIVATE.LOGGED_IN_FRAME.MENU.DASHBOARD'),
                route: DASHBOARD,
                onNavigate: () =>
                  navigate(
                    buildDashboardUrl({
                      [SearchParams.CLIENT_ID]: _clientId,
                      [SearchParams.PROJECT_ID]: _projectId,
                    }),
                  ),
              },
              { label: t('PRIVATE.LOGGED_IN_FRAME.MENU.TEAM'), route: TEAM },
            ];
      default:
        return [];
    }
  }, [_clientId, _projectId, finalWhitelabel?.length, isCurrentUser, navigate, t, user?.role]);

  const handleLogout = useCallback(async () => {
    const response = await logout();
    if (response) {
      dispatch(RESET);
    }
  }, [dispatch]);

  const handleDropdownValueSelect = useCallback(
    (option: { name: string; id: string }) => {
      if (option.id === 'logOut') {
        handleLogout();
      } else if (option.id === 'settings') {
        navigate(userHasAdminRole(user) ? `/${ADMIN_SETTINGS}` : `/${SETTINGS}`);
      }
    },
    [handleLogout, navigate, user],
  );

  return (
    <div className={styles.mainContainer}>
      <div className={navBarStyle}>
        <div className={styles.logoAndMenu}>
          <span className={styles.logo}>
            {!!whitelabel?.logoContent?.length ? (
              <img src={whitelabel.logoContent} alt={'logo'} />
            ) : color === 'white' ? (
              getLogoBlack()
            ) : (
              getLogoWhite()
            )}
          </span>
          {menu.map(({ label, route, onNavigate }, index) => (
            <span
              key={index}
              className={clsx(styles.menuItem, {
                [styles.menuItemHighlighted]: pathname.startsWith(`/${route}`),
              })}
              onClick={() => {
                if (onNavigate) {
                  onNavigate();
                } else {
                  navigate(`/${route}`);
                }
              }}
            >
              {label}
            </span>
          ))}
        </div>
        {hasSession && (
          <div className={styles.iconContainer}>
            {/*<div className={styles.notificationContainer}>*/}
            {/*  {color === 'white' ? <NotificationIcon /> : <WhiteNotificationIcon />}*/}
            {/*  <div className={notificationBackground}>*/}
            {/*    <div className={styles.notification} />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {user?.profilePic && <img src={user.profilePic} alt="" className={styles.profilePic} />}
            <Dropdown
              chooseValue={handleDropdownValueSelect}
              variants={[
                { name: t('PRIVATE.LOGGED_IN_FRAME.SETTINGS'), id: 'settings' },
                { name: t('PRIVATE.LOGGED_IN_FRAME.LOG_OUT'), id: 'logOut' },
              ]}
            >
              <div className={styles.userName}>
                <Paragraph size="medium">{t('PRIVATE.LOGGED_IN_FRAME.WELCOME', { name: user?.firstName })}</Paragraph>
                <span className={styles.dropDownIcon}>
                  {color === 'white' ? <ChevronIcon /> : <WhiteChevronIcon />}
                </span>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
};

export default LoggedInFrame;
