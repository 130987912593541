export const capitalizeFirstLetter = (value: string | null): string | null => {
  if (value && value.length > 0) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    return null;
  }
};

export const deCapitalizeFirstLetter = (value: string | null): string | null => {
  if (value && value.length > 0) {
    return value.charAt(0).toLowerCase() + value.slice(1);
  } else {
    return null;
  }
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
export const websiteRegex =
  /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;

export const makeId = (len: number | undefined = 16): string => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const disemvowel = (str: string): string => (str ? str.replace(/[aeiouy]/gi, '') : '');

export const getObjectUrlParams = (obj = {}): string => {
  const params = {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(obj).map((key) => (params[key] = obj[key])); //(params[disemvowel(key).toLocaleLowerCase()] = obj[key]));
  return new URLSearchParams(params).toString();
};
