import React from 'react';
import { Link, Paragraph } from 'components/common';
import { Props } from './type';

import styles from './AuthFormWrapper.module.scss';

const AuthFormWrapper = ({ children, linkText, linkHref, hintMessage, withBottomLine }: Props) => {
  return (
    <div className={styles.content}>
      {children}
      {withBottomLine && <div className={styles.bottomLine} />}
      <div className={styles.linkContainer}>
        {!!hintMessage && <Paragraph className={styles.linkContainerText}>{hintMessage}</Paragraph>}
        <Link className={styles.bottomLink} href={linkHref}>
          {linkText}
        </Link>
      </div>
    </div>
  );
};

export default AuthFormWrapper;
