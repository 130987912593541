import React from 'react';
import clsx from 'classnames';
import { Props } from './type';
import { ReactComponent as DangerIcon } from 'assets/img/danger.svg';

import styles from './Input.module.scss';

const Input = ({
  style,
  classNameWrapper,
  className,
  icon,
  alignIcon = 'left',
  disabled,
  label,
  error,
  ...rest
}: Props): JSX.Element => {
  const inputStyle = clsx(styles.inputWrapper, {
    [`${className}`]: !!className,
    [styles.disabled]: disabled,
    [styles.inputError]: !!error,
    [styles.withIconLeft]: !!icon && alignIcon === 'left',
    [styles.withIconRight]: !!icon && alignIcon === 'right',
  });

  const iconStyle = clsx({
    [styles.icon]: !!icon,
    [styles.iconLeft]: alignIcon === 'left',
    [styles.iconRight]: alignIcon === 'right',
  });

  return (
    <div
      className={clsx(styles.main, {
        [`${classNameWrapper}`]: !!classNameWrapper,
      })}
    >
      {!!label && label}
      <div style={style} className={styles.container}>
        {icon && <div className={iconStyle}>{icon}</div>}
        <input className={inputStyle} {...rest} />
      </div>
      {!!error?.length && (
        <div className={styles.errorMessage}>
          <DangerIcon />
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default Input;
