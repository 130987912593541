import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import moment from 'moment';
import { Modal } from 'antd';
import { useMutation } from '@apollo/client';
import { Form, Formik, FormikHelpers } from 'formik';
import { Project } from 'types/businessTypes';
import { toast } from 'react-toastify';
import { upsertProject } from 'graphql/businessMutations';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { projectTitleMaxLength } from 'utils/businessUtils';
import logger from 'utils/logger/logger';
import { ProjectInput } from 'types/inputTypes';
import { Button, Checkbox, Input, RadioButton } from 'components/common';
import { useTrackedSelector } from 'components/app/store';
import { iso8601DateFormat } from 'utils/dateTimeUtils';
import DatePicker from 'components/common/datePicker';
import ToasterInfo from 'components/common/toasterInfo';
import { Client, ClientPaymentModel } from 'types/types.d';

import styles from './CreateProjectModal.module.scss';
import { getContactUsMessage, getLocalisedClientPaymentModel } from 'utils/i18nUtils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean, existingNames: string[]): any | (() => any) => {
  return Yup.object().shape({
    title: validateOnSubmit
      ? Yup.string()
          .max(projectTitleMaxLength, t('VALIDATION.PROJECT_TITLE_MAX_LENGTH', { length: projectTitleMaxLength }))
          .test('name-test', t('VALIDATION.TITLE_TAKEN'), (value) => {
            return !existingNames.find((i) => i === value);
          })
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .max(projectTitleMaxLength, t('VALIDATION.PROJECT_TITLE_MAX_LENGTH', { length: projectTitleMaxLength }))
          .optional(),
  });
};

interface Values {
  paymentModel: ClientPaymentModel;
  title: string;
  deliveryDate: string;
  ugc: boolean;
}

interface Props {
  projects: Project[];
  onHide: () => void;
  onDone: (project: Project) => void;
  onBriefRequired: (project: Project) => void;
  client?: Client;
}

export default function CreateProjectModal({ projects, onHide, onDone, onBriefRequired, client }: Props): JSX.Element {
  const { t } = useTranslation();

  const {
    auth: { user },
  } = useTrackedSelector();

  const [doCreate] = useMutation<{ upsertProject?: Project }>(upsertProject);

  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [withBrief, setWithBrief] = useState(true);

  const paymentModels = useMemo(() => client?.paymentModels || [ClientPaymentModel.STANDARD], [client?.paymentModels]);

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const handleSubmit = useCallback(
    ({ paymentModel, title, deliveryDate, ugc }: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
      setSubmitting(true);

      doCreate({
        variables: {
          input: {
            paymentModel,
            title,
            deliveryDate,
            ugc: paymentModel === ClientPaymentModel.STANDARD ? ugc : null,
            clientId: client?.id || user?.clientId,
          } as ProjectInput,
        },
      })
        .then((result) => {
          if (result?.data?.upsertProject) {
            showSuccessNotification(t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.CREATE_SUCCESS'));
            if (withBrief) {
              onBriefRequired(result?.data?.upsertProject as Project);
            } else {
              onDone(result?.data?.upsertProject as Project);
            }
          } else {
            logger.error('No result for project add');
            showErrorNotification();
            setSubmitting(false);
          }
        })
        .catch((error) => {
          logger.error(`Add project error: ${error}`);
          showErrorNotification();
          setSubmitting(false);
        });
    },
    [
      client?.id,
      doCreate,
      onBriefRequired,
      onDone,
      showErrorNotification,
      showSuccessNotification,
      t,
      user?.clientId,
      withBrief,
    ],
  );

  return (
    <Modal open={true} onCancel={onHide} className={styles.modal} footer={null} maskClosable={false}>
      <div className={styles.modalHeader}>
        <div className={styles.title}>{t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.CREATE_TITLE')}</div>
      </div>
      <div className={styles.body}>
        <Formik
          validationSchema={validationSchema(
            t,
            submitTriggered,
            projects.map((i) => i.title),
          )}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{
            paymentModel: !!paymentModels.length ? paymentModels[0] : ClientPaymentModel.STANDARD,
            title: '',
            deliveryDate: moment().add(1, 'week').format(iso8601DateFormat),
            ugc: false,
          }}
        >
          {({ handleChange, handleBlur, values, errors, isSubmitting, setFieldValue }) => {
            return (
              <Form noValidate className={styles.form}>
                {paymentModels.length > 1 && (
                  <div className={styles.formItem}>
                    <div className={styles.formItemLabel}>
                      {t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.PAYMENT_MODEL')}
                    </div>
                    <div
                      className={styles.formItemRadioGroup}
                      onChange={(e) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const value = e.target?.id;
                        handleChange({ target: { value, name: 'paymentModel' } } as unknown as ChangeEvent);
                      }}
                    >
                      {[ClientPaymentModel.STANDARD, ClientPaymentModel.PERFORMANCE].map((i) => (
                        <RadioButton
                          key={i}
                          id={i}
                          name="paymentModel"
                          label={getLocalisedClientPaymentModel(i, t)}
                          alignLabel="right"
                          checked={values.paymentModel === i}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <div className={styles.formItem}>
                  <div className={styles.formItemLabel}>{t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.TITLE')}</div>
                  <Input
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    error={!!errors.title?.length ? errors.title : undefined}
                  />
                </div>
                <div className={styles.formItem}>
                  <div className={styles.formItemLabel}>{t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.DELIVERY_DATE')}</div>
                  <DatePicker
                    date={values.deliveryDate}
                    returnFormat={iso8601DateFormat}
                    onChange={(value) => setFieldValue('deliveryDate', moment(value).format(iso8601DateFormat), true)}
                  />
                </div>
                {values.paymentModel === ClientPaymentModel.STANDARD && (
                  <div className={styles.formItem}>
                    <div className={styles.formItemLabel}>{t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.UGC')}</div>
                    <Checkbox
                      className={styles.ugc}
                      label={t('COMMON.YES')}
                      labelAfter={true}
                      name="ugc"
                      onBlur={handleBlur}
                      checked={values.ugc}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className={styles.buttons}>
                  <Button
                    className={styles.button}
                    buttonType="tertiary"
                    type="submit"
                    disabled={!values.title.length || !!errors.title || !!errors.deliveryDate || isSubmitting}
                    onClick={() => {
                      setWithBrief(false);
                      setSubmitTriggered(true);
                    }}
                  >
                    {t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.WITHOUT_BRIEF_CTA')}
                  </Button>
                  <div className={styles.mainButtons}>
                    <Button className={styles.button} buttonType="secondary" disabled={isSubmitting} onClick={onHide}>
                      {t('COMMON.CANCEL')}
                    </Button>
                    <Button
                      className={styles.button}
                      buttonType="primary"
                      type="submit"
                      disabled={!values.title.length || !!errors.title || !!errors.deliveryDate || isSubmitting}
                      onClick={() => setSubmitTriggered(true)}
                    >
                      {t('PRIVATE.USER.DASHBOARD.UPSERT_PROJECT.WITH_BRIEF_CTA')}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}
