import { FC, memo, useCallback, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { Bin } from './Bin';
import ContentItem from './ContentItem';

export enum DDType {
  DRAG_TYPE = 'DRAG_TYPE',
  DROP_TYPE = 'DROP_TYPE',
}

interface Props {
  index: number;
  mediaName: string;
  mediaType: string;
  mediaSize: number;
  mediaFilePath: string;
  onDrop: (currentIndex: number, newIndex: number) => void;
  onNameChange: (value: string) => void;
  onDelete: (index: number) => void;
  type: DDType.DRAG_TYPE | DDType.DROP_TYPE;
}

export const DraggableItem: FC<Props> = memo(function DraggableItem({
  index,
  mediaName,
  mediaType,
  mediaSize,
  mediaFilePath,
  onDrop,
  onNameChange,
  onDelete,
  type,
}) {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: {
        index,
        mediaName,
        mediaType,
        mediaSize,
        mediaFilePath,
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.3 : 1,
        isDragging: monitor.isDragging(),
        monitor,
      }),
    }),
    [index],
  );

  const acceptType = useMemo(() => (type === DDType.DROP_TYPE ? DDType.DRAG_TYPE : ''), [type]);

  const handleDrop = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ index: droppedIndex }: { index: number }) => {
      return onDrop(droppedIndex, index);
    },
    [index, onDrop],
  );

  const content = (
    // eslint-disable-next-line react/react-in-jsx-scope
    <ContentItem
      index={index}
      mediaName={mediaName}
      mediaType={mediaType}
      mediaSize={mediaSize}
      mediaFilePath={mediaFilePath}
      withDrag={true}
      onNameChange={onNameChange}
      onDelete={() => onDelete(index)}
      type={type}
    />
  );

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div ref={drag} role="DraggableItem" style={{ opacity }}>
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <Bin accept={[acceptType]} onDrop={handleDrop} content={content} />
    </div>
  );
});
