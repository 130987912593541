import React, { useCallback, useState } from 'react';
import DropDownMenu from '../dropdownMenu';
import { Variant } from '../dropdownMenu/types';
import { Props } from '../dropdown/type';
import clsx from 'classnames';
import styles from './Dropdown.module.scss';
import { OutsideClick } from 'components/common';

const Dropdown = ({ variants, chooseValue, children, className, containerClassname }: Props) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const toggleDropdownMenu = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  const handleVariantSelect = useCallback(
    (variant: Variant) => {
      toggleDropdownMenu();
      chooseValue(variant);
    },
    [chooseValue, toggleDropdownMenu],
  );

  const dropDownMenuStyle = clsx(styles.dropDownMenu, {
    [`${className}`]: !!className,
  });

  return (
    <div className={styles.dropDownWrapper}>
      <OutsideClick onOutsideClick={() => setIsOpened(false)}>
        <div onClick={toggleDropdownMenu}>{children}</div>
        <div className={dropDownMenuStyle}>
          <DropDownMenu
            containerClassname={containerClassname}
            open={isOpened}
            chooseValue={handleVariantSelect}
            variants={variants}
          />
        </div>
      </OutsideClick>
    </div>
  );
};

export default Dropdown;
