import React, { useCallback, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Modal } from 'antd';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Form, Formik, FormikHelpers } from 'formik';
import { Ad, AdMedia, AdType, IMAGE_KIT_TEMP_FOLDER, ImageKitFileDetails } from 'types/businessTypes.d';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { adTitleMaxLength, getAssetRatio, getImageKitPath, greatestCommonDivisor } from 'utils/businessUtils';
import { black_500 } from 'utils/styleUtils';
import logger from 'utils/logger/logger';
import { ContentType, findContentType } from 'types/contentTypes';
import { Button, Input, Loading, RadioButton } from 'components/common';
import { createAd, createAdsFromMedia, updateAd } from 'graphql/businessMutations';
import { ReactComponent as UploadIcon } from 'assets/img/uploadFile.svg';
import { IKContext, IKImage, IKVideo } from 'imagekitio-react';
import { useTrackedSelector } from 'components/app/store';
import cloneDeep from 'lodash/cloneDeep';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DDType, DraggableItem } from './DraggableItem';
import ContentItem from './ContentItem';
import { uploadToImageKit } from 'services/imagekit.service';
import { authImageKit } from 'services/auth.service';
import ToasterInfo from 'components/common/toasterInfo';
import { AD_VIEW, CLIENT_ID, PROJECT_ID } from 'utils/routingUtils';
import { getDomain } from 'utils/windowUtils';
import styles from './CreateAddModal.module.scss';
import { getContactUsMessage } from 'utils/i18nUtils';
import { ReactComponent as DeleteIcon } from 'assets/img/delete.svg';

const acceptStyle = {
  borderColor: black_500,
};

const maxCarouselFiles = 10;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean): any | (() => any) => {
  return Yup.object().shape({
    title: validateOnSubmit
      ? Yup.string()
          .max(adTitleMaxLength, t('VALIDATION.AD_TITLE_MAX_LENGTH', { length: adTitleMaxLength }))
          .when('type', {
            is: (value: AdType) => value,
            then: Yup.string().test('title-test', t('VALIDATION.REQUIRED'), (value, context) => {
              const {
                parent: { type },
              } = context;
              return [AdType.CAROUSEL, AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(type)
                ? !!value?.length
                : true;
            }),
          })
          .optional()
      : Yup.string()
          .max(adTitleMaxLength, t('VALIDATION.AD_TITLE_MAX_LENGTH', { length: adTitleMaxLength }))
          .optional(),
    type: Yup.string().oneOf([
      AdType.SINGLE_IMAGE,
      AdType.CAROUSEL,
      AdType.SINGLE_VIDEO,
      AdType.MULTI_SIZE_IMAGE,
      AdType.MULTI_SIZE_VIDEO,
    ]),
  });
};

interface Values {
  title: string;
  type: AdType;
}

interface Props {
  clientId: string;
  projectId: string;
  onHide: () => void;
  onDone: () => void;
  ad?: Ad;
}

export default function CreateAdModal({ clientId, projectId, onHide, onDone, ad }: Props): JSX.Element {
  const { t } = useTranslation();

  // const uploadRef = useRef(null);

  const [doCreateAd] = useMutation<{ createAd?: Ad }>(createAd);
  const [doCreateAds] = useMutation<{ createAdsFromMedia?: Ad[] }>(createAdsFromMedia);
  const [doUpdate] = useMutation<{ updateAd?: Ad }>(updateAd);

  const {
    config: { imagekitUrl, imagekitPublicKey, imagekitAuthEndpoint },
  } = useTrackedSelector();

  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [medias, setMedias] = useState<AdMedia[]>(!!ad?.versions?.length ? ad.versions[0].medias : []);
  const [loading, setLoading] = useState(false);

  const getPathToAd = useCallback(
    (adId: string) => `/${AD_VIEW}/${adId}?${CLIENT_ID}=${clientId}&${PROJECT_ID}=${projectId}`,
    [clientId, projectId],
  );

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const getMediaPath = useCallback(
    (fileName: string, isNew: boolean) => {
      return ad && !isNew
        ? getImageKitPath({
            clientId,
            projectId,
            adId: ad.id,
            versionId: ad.versions[0].id,
            fileName,
          })
        : `${IMAGE_KIT_TEMP_FOLDER}/${fileName}`;
    },
    [ad, clientId, projectId],
  );

  /**
   * - "single ad(s)" creates an ad per a media
   * - "carousel" creates a single ad with multiple medias
   */
  const handleSubmit = useCallback(
    ({ type, title }: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
      setSubmitting(true);
      setLoading(true);

      const createAd = (type: AdType, title: string, medias: AdMedia[]) => {
        // creating either a carousel or multiple single ads at once
        const variables = [AdType.CAROUSEL, AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(type)
          ? {
              projectId,
              type,
              title,
              medias: medias.map(({ imageKitName, name, type, size, mime, height, width, ratio }) => ({
                imageKitName,
                name,
                type,
                size,
                mime,
                height,
                width,
                ratio,
              })),
            }
          : {
              projectId,
              type,
              medias: medias.map(({ imageKitName, name, type, size, mime, height, width, ratio }) => ({
                imageKitName,
                name,
                type,
                size,
                mime,
                height,
                width,
                ratio,
              })),
            };
        ([AdType.CAROUSEL, AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(type) ? doCreateAd : doCreateAds)(
          { variables },
        )
          .then((result) => {
            if (result?.data) {
              setLoading(false);
              showSuccessNotification(t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.CREATE_SUCCESS'));
              onDone();
            } else {
              setLoading(false);
              logger.error('No result for ad add');
              showErrorNotification();
              setSubmitting(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            logger.error(`Add ad error: ${error}`);
            showErrorNotification();
            setSubmitting(false);
          });
      };

      const updateAd = (title: string, medias: AdMedia[]) => {
        if (!ad) return;

        const variables = {
          id: ad.id,
          link: `${getDomain()}/#${getPathToAd(ad.id)}`,
          title,
          medias: medias.map(({ imageKitName, name, type, size, mime, height, width, ratio }) => ({
            imageKitName,
            name,
            type,
            size,
            mime,
            height,
            width,
            ratio,
          })),
        };
        doUpdate({ variables })
          .then((result) => {
            if (result?.data) {
              setLoading(false);
              showSuccessNotification(t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.UPDATE_SUCCESS'));
              onDone();
            } else {
              setLoading(false);
              logger.error('No result for ad add');
              showErrorNotification();
              setSubmitting(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            logger.error(`Add ad error: ${error}`);
            showErrorNotification();
            setSubmitting(false);
          });
      };

      switch (type) {
        case AdType.SINGLE_IMAGE:
        case AdType.SINGLE_VIDEO:
          ad ? updateAd(title, medias) : createAd(type, '', medias);
          break;
        case AdType.CAROUSEL:
        case AdType.MULTI_SIZE_IMAGE:
        case AdType.MULTI_SIZE_VIDEO:
          if (title) ad ? updateAd(title, medias) : createAd(AdType.CAROUSEL, title, medias);
          break;
      }
    },
    [
      ad,
      doCreateAd,
      doCreateAds,
      doUpdate,
      getPathToAd,
      medias,
      onDone,
      projectId,
      showErrorNotification,
      showSuccessNotification,
      t,
    ],
  );

  const onMediaUploadedToImageKit = useCallback((data: ImageKitFileDetails[]) => {
    const newMedias = data.map((details) => {
      return {
        imageKitName: details.name,
        name: details.name.substring(0, details.name.lastIndexOf('.')), // cut extension off
        type: details.fileType,
        size: details.size,
        mime: details.mime ? details.mime : findContentType(details.name.substring(details.name.lastIndexOf('.') + 1)),
        height: details.height,
        width: details.width,
        ratio: getAssetRatio(details.width, details.height),
        isNew: true,
      } as AdMedia;
    });
    setMedias((prev) => {
      // new array required so that react update happens
      const newArray = cloneDeep(prev);
      newArray.push(...newMedias);
      return newArray;
    });
  }, []);

  // D&D and file selection

  // upload all files at once
  const uploadFiles = useCallback(
    async (files: { base64: string; fileName: string }[]) => {
      // upload via BE
      // doUpload({ variables: { input: files } })
      //   .then((result) => {
      //     const details = result?.data?.uploadMediaFiles;
      //     if (details) {
      //       onMediaUploadedToImageKit(details);
      //     } else {
      //       logger.error(`Upload medias ad error`);
      //       showErrorNotification();
      //     }
      //   })
      //   .catch((e) => {
      //     logger.error(`Upload medias ad error: ${e}`);
      //     showErrorNotification();
      //   })
      //   .finally(() => setLoading(false));

      // direct upload from client to ImageKit
      try {
        const result: ImageKitFileDetails[] = [];
        for (const file of files) {
          const auth = await authImageKit();
          if (auth) {
            const uploaded = await uploadToImageKit({
              file: file.base64,
              fileName: file.fileName,
              folder: IMAGE_KIT_TEMP_FOLDER,
              useUniqueFileName: true,
              publicKey: imagekitPublicKey,
              signature: auth.signature,
              expire: auth.expire,
              token: auth.token,
            });

            if (uploaded) {
              result.push(uploaded);
            } else {
              logger.error(`Upload media error`);
            }
          }
        }
        onMediaUploadedToImageKit(result);
      } catch (e) {
        logger.error(`Upload medias error: ${e}`);
        showErrorNotification();
      } finally {
        setLoading(false);
      }
    },
    [imagekitPublicKey, onMediaUploadedToImageKit, showErrorNotification],
  );

  const onDrop = useCallback(
    (acceptedFiles: Blob[]) => {
      const files: { base64: string; fileName: string }[] = [];
      acceptedFiles.forEach((file: Blob, index) => {
        const reader = new FileReader();
        reader.onerror = () => showErrorNotification();
        reader.onload = ((theFile) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (e: any) => {
            const content = e.target.result;
            setLoading(true);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (content) files.push({ base64: content, fileName: theFile.name });
            if (index === acceptedFiles.length - 1) uploadFiles(files);
          };
        })(file);
        reader.readAsDataURL(file);
      });
    },
    [showErrorNotification, uploadFiles],
  );

  const handleMediaDelete = useCallback((indexToDelete: number) => {
    setMedias((prev) => {
      // new array required so that react update happens
      const newArray = cloneDeep(prev);
      return newArray.filter((i, index) => index !== indexToDelete);
    });
  }, []);

  const handleMediaNameChange = useCallback((value: string, index: number) => {
    setMedias((prev) => {
      prev[index].name = value;
      // new array required so that react update happens
      const newArray = cloneDeep(prev);
      newArray[index].name = value;
      return newArray;
    });
  }, []);

  const handleMediasShuffle = useCallback((currentIndex: number, newIndex: number) => {
    if (currentIndex === newIndex || currentIndex === newIndex - 1) return;

    setMedias((prev) => {
      // new array required so that react update happens
      const newArray = cloneDeep(prev);
      newArray.splice(newIndex, 0, newArray.splice(currentIndex, 1)[0]);
      return newArray;
    });
  }, []);

  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxFiles: maxCarouselFiles,
    maxSize: 100 * 1000000, // imagekit max file size is 25MB in the free package
    onDrop,
    accept: {
      [`${ContentType.IMAGE_PNG}`]: ['.png'],
      [`${ContentType.IMAGE_JPG}`]: ['.jpg'],
      [`${ContentType.IMAGE_JPEG}`]: ['.jpeg'],
      [`${ContentType.IMAGE_GIF}`]: ['.gif'],
      [`${ContentType.IMAGE_WEBP}`]: ['.webp'],
      [`${ContentType.IMAGE_SVG_XML}`]: ['.svg'],
      [`${ContentType.VIDEO_MPEG}`]: ['.mpeg'],
      [`${ContentType.VIDEO_MP4}`]: ['.mp4'],
      [`${ContentType.VIDEO_MOV}`]: ['.mov'],
    },
  });

  const style = useMemo(
    () => ({
      ...(isDragAccept ? acceptStyle : {}),
    }),
    [isDragAccept],
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rootProps = useMemo(
    () =>
      getRootProps({
        className: !!medias.length ? styles.dropzoneWithMedias : styles.dropzone,
        style,
      }),
    [getRootProps, medias.length, style],
  );

  return (
    <IKContext urlEndpoint={imagekitUrl} publicKey={imagekitPublicKey} authenticationEndpoint={imagekitAuthEndpoint}>
      <Modal open={true} onCancel={onHide} className={styles.modal} footer={null} maskClosable={false} width={1100}>
        <div className={styles.modalHeader}>
          <div className={styles.title}>
            {!ad
              ? t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.ADD')
              : t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.EDIT')}
          </div>
        </div>
        <div className={styles.body}>
          {loading && (
            <div className={styles.loadingWrapper}>
              <Loading />
            </div>
          )}
          <Formik
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            validationSchema={validationSchema(t, submitTriggered)}
            onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
            initialValues={{
              title: ad?.title || '',
              type: ad?.type || AdType.SINGLE_IMAGE,
            }}
          >
            {({ handleChange, handleBlur, values, errors, isSubmitting }) => (
              <Form noValidate className={styles.form}>
                {!ad && (
                  <div className={styles.formItem}>
                    <div className={styles.formItemLabel}>
                      {t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.TYPE')}
                    </div>
                    <div className={styles.formItem__ad_type}>
                      {[
                        { type: AdType.SINGLE_IMAGE, label: t('AD_TYPE.SINGLE_AD') },
                        { type: AdType.CAROUSEL, label: t('AD_TYPE.CAROUSEL') },
                      ].map(({ type, label }) => (
                        <RadioButton
                          key={type}
                          name="type"
                          label={label}
                          alignLabel="right"
                          value={type}
                          onChange={handleChange}
                          checked={values.type === type}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {(!!ad ||
                  [AdType.CAROUSEL, AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(values.type)) && (
                  <div className={styles.formItem}>
                    <Input
                      type="title"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      error={!!errors.title?.length ? errors.title : undefined}
                    />
                  </div>
                )}

                <div className={styles.maxFiles}>
                  {values.type === AdType.CAROUSEL && (
                    <span>{t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.CAROUSEL_FILES')}</span>
                  )}
                  {(!!ad || [AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(values.type)) && (
                    <span>{t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.MULTI_SIZE_FILES')}</span>
                  )}
                  <span>{t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.MAX_FILES_COUNT_1')}</span>
                  <span style={{ fontWeight: '700' }}>
                    {t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.MAX_FILES_COUNT_2', { max: maxCarouselFiles })}
                  </span>
                </div>

                <div className={styles.mainSection}>
                  {/* d&d and file selector */}
                  <div
                    {...rootProps}
                    style={{
                      pointerEvents:
                        (!!ad ||
                          [AdType.CAROUSEL, AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(values.type)) &&
                        medias.length === maxCarouselFiles
                          ? 'none'
                          : undefined,
                    }}
                  >
                    <input {...getInputProps()} />
                    <div>
                      <UploadIcon />
                    </div>
                    <div className={styles.upload}>
                      {!ad && [AdType.SINGLE_IMAGE, AdType.SINGLE_VIDEO].includes(values.type)
                        ? t('COMMON.UPLOAD_FILE')
                        : t('COMMON.UPLOAD_FILES')}
                    </div>
                    <div className={styles.dragAndDrop}>
                      <div className={styles.text1}>{t('COMMON.CLICK_TO_BROWSE_1')}</div>
                      {/*<IKUpload*/}
                      {/*  onUploadStart={() => setLoading(true)}*/}
                      {/*  onSuccess={(details) => {*/}
                      {/*    setLoading(false);*/}
                      {/*    onMediaUploadedToImageKit([details]);*/}
                      {/*  }}*/}
                      {/*  onError={(e) => {*/}
                      {/*    logger.log(e);*/}
                      {/*    showErrorNotification();*/}
                      {/*  }}*/}
                      {/*  folder={IMAGE_KIT_TEMP_FOLDER}*/}
                      {/*  useUniqueFileName={true} // at first uploading to a temp folder, that's why unique names are needed*/}
                      {/*  style={{ display: 'none' }} // hide default button*/}
                      {/*  inputRef={uploadRef}*/}
                      {/*/>*/}
                      <Button
                        buttonType="tertiary"
                        // onClick={() => {
                        //   if (uploadRef?.current) {
                        //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //     // @ts-ignore
                        //     uploadRef.current.click();
                        //   }
                        // }}
                        onClick={open}
                        type="button"
                        className={styles.browse}
                        disabled={
                          [AdType.CAROUSEL, AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(values.type) &&
                          medias.length === maxCarouselFiles
                        }
                      >
                        {t('COMMON.CLICK_TO_BROWSE_2')}
                      </Button>
                    </div>
                    <div className={styles.text2}>{t('COMMON.CLICK_TO_BROWSE_3')}</div>
                  </div>

                  {/* multiple sizes are NOT allowed if it's explicitly not such a type and it's not ad editing or is carousel */}
                  {!!medias.length &&
                    ![AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(values.type) &&
                    (!ad || [AdType.CAROUSEL].includes(values.type)) && (
                      <div className={styles.mediasWrapper}>
                        {values.type === AdType.CAROUSEL && (
                          <div className={styles.slides}>
                            <div>
                              {medias.map((media, index) => (
                                <div key={index}>
                                  <div className={styles.slide}>{`${t(
                                    'PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.SLIDE',
                                  )} ${index + 1}`}</div>
                                  <div className={styles.separator} />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        <DndProvider backend={HTML5Backend}>
                          <div className={styles.medias}>
                            {medias.map((media, index) => {
                              return values.type === AdType.CAROUSEL ? (
                                <div key={index}>
                                  <DraggableItem
                                    key={`${index}-before`}
                                    index={index}
                                    mediaName={media.name}
                                    mediaType={media.type}
                                    mediaSize={media.size}
                                    mediaFilePath={getMediaPath(media.imageKitName, media.isNew || false)}
                                    onDrop={handleMediasShuffle}
                                    onNameChange={(value) => handleMediaNameChange(value, index)}
                                    onDelete={handleMediaDelete}
                                    type={DDType.DROP_TYPE}
                                  />
                                  <DraggableItem
                                    key={index}
                                    index={index}
                                    mediaName={media.name}
                                    mediaType={media.type}
                                    mediaSize={media.size}
                                    mediaFilePath={getMediaPath(media.imageKitName, media.isNew || false)}
                                    onDrop={handleMediasShuffle}
                                    onNameChange={(value) => handleMediaNameChange(value, index)}
                                    onDelete={handleMediaDelete}
                                    type={DDType.DRAG_TYPE}
                                  />
                                  {index === medias.length - 1 && (
                                    <DraggableItem
                                      key={`${index}-after`}
                                      index={index + 1}
                                      mediaName={media.name}
                                      mediaType={media.type}
                                      mediaSize={media.size}
                                      mediaFilePath={getMediaPath(media.imageKitName, media.isNew || false)}
                                      onDrop={handleMediasShuffle}
                                      onNameChange={(value) => handleMediaNameChange(value, index)}
                                      onDelete={handleMediaDelete}
                                      type={DDType.DROP_TYPE}
                                    />
                                  )}
                                </div>
                              ) : (
                                <div key={index}>
                                  <ContentItem
                                    index={index}
                                    mediaName={media.name}
                                    mediaType={media.type}
                                    mediaSize={media.size}
                                    mediaFilePath={getMediaPath(media.imageKitName, media.isNew || false)}
                                    withDrag={false}
                                    onNameChange={(value) => handleMediaNameChange(value, index)}
                                    onDelete={handleMediaDelete}
                                  />
                                  <div className={styles.separator} />
                                </div>
                              );
                            })}
                          </div>
                        </DndProvider>
                      </div>
                    )}

                  {/* multiple sizes: if it's not carousel && is explicitly such a type or it's ad editing & not carousel */}
                  {![AdType.CAROUSEL].includes(values.type) &&
                    !!medias.length &&
                    ([AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(values.type) || !!ad) && (
                      <div className={styles.multiSizeMediasWrapper}>
                        <div className={styles.medias}>
                          {medias.map((media, index) => {
                            let ratio1 = null;
                            let ratio2 = null;
                            let transformationMultiple = null;
                            if (media.width && media.height) {
                              const gcd = greatestCommonDivisor(media.width, media.height);
                              ratio1 = media.width / gcd;
                              ratio2 = media.height / gcd;
                              if (ratio1 > 100) {
                                transformationMultiple = 1;
                              } else {
                                transformationMultiple = ratio1 === 1 ? 100 : ratio1 < 4 ? 60 : ratio1 < 6 ? 30 : 5;
                              }
                            }
                            // console.log({
                            //   width: media.width,
                            //   height: media.height,
                            //   transformationMultiple,
                            //   ratio: `${ratio1}:${ratio2}`,
                            // });

                            return ratio1 && ratio2 && transformationMultiple ? (
                              <div key={index} className={styles.media}>
                                <div className={styles.mediaHeader}>
                                  {/*<Dropdown*/}
                                  {/*  menu={{*/}
                                  {/*    items: ['1:1', '5:4', '4:5', '4:3', '3:2', '7:5', '9:16', '16:9'].map((i) => ({*/}
                                  {/*      key: i,*/}
                                  {/*      label: i,*/}
                                  {/*    })),*/}
                                  {/*    onClick: ({ key }) => {*/}
                                  {/*      console.log(key);*/}
                                  {/*    },*/}
                                  {/*  }}*/}
                                  {/*>*/}
                                  {/*  <div className={styles.sizeSelector}>*/}
                                  {/*    <span>*/}
                                  {/*      {ratio1}:{ratio2}*/}
                                  {/*    </span>*/}
                                  {/*    <span>{<ChevronIcon />}</span>*/}
                                  {/*  </div>*/}
                                  {/*</Dropdown>*/}
                                  <div className={styles.sizeSelector}>
                                    <span>
                                      {ratio1}:{ratio2}
                                    </span>
                                  </div>
                                  <DeleteIcon className={styles.delete} onClick={() => handleMediaDelete(index)} />
                                </div>
                                {media.type === 'image' ? (
                                  <IKImage
                                    path={getMediaPath(media.imageKitName, media.isNew || false)}
                                    onError={(e) => logger.error(e)}
                                    transformation={[
                                      {
                                        width: `${ratio1 * transformationMultiple}`,
                                        height: `${ratio2 * transformationMultiple}`,
                                      },
                                    ]}
                                  />
                                ) : (
                                  <div
                                    className={styles.video}
                                    style={{
                                      width: `${ratio1 * transformationMultiple}px`,
                                      height: `${ratio2 * transformationMultiple}px`,
                                    }}
                                  >
                                    <IKVideo
                                      path={getMediaPath(media.imageKitName, media.isNew || false)}
                                      onError={(e) => logger.error(e)}
                                      // transformation={[{ height: '112', width: '112' }]}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <></>
                            );
                          })}
                        </div>
                      </div>
                    )}
                </div>

                <div className={styles.buttons}>
                  <Button
                    className={styles.button}
                    type="button"
                    buttonType="secondary"
                    disabled={isSubmitting}
                    onClick={onHide}
                  >
                    {t('COMMON.CANCEL')}
                  </Button>
                  <Button
                    className={styles.button}
                    buttonType="primary"
                    type="submit"
                    disabled={
                      ((!!ad ||
                        [AdType.CAROUSEL, AdType.MULTI_SIZE_IMAGE, AdType.MULTI_SIZE_VIDEO].includes(values.type)) &&
                        !values.title.length) ||
                      !!errors.title ||
                      !medias.length ||
                      isSubmitting
                    }
                    onClick={() => setSubmitTriggered(true)}
                  >
                    {ad ? t('COMMON.SAVE') : t('COMMON.UPLOAD')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </IKContext>
  );
}
