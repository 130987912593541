import logger from './logger/logger';

export const desktop = 992;
export const xxlDesktop = 1400;

export const openInNewTab = (url: string): Window | null => {
  return window.open(url, '_blank', 'noopener,noreferrer');
};

export const redirectToExternalUrl = (url: string): void => {
  window.location.href = url;
};

export const getDomain = (): string => window.location.origin;

export const reloadPage = (): void => window.location.reload();

export const copyToClipboard = (content: string): void => {
  navigator.clipboard
    .writeText(content)
    .then(() => logger.log(`'${content}' copied to clipboard`))
    .catch((error) => logger.error(`Failed to copy to clipboard: ${error}`));
};

/**
 * @return map with search params found after "?" and split by "&" and "=" correspondingly
 */
export const getSearchParamsFromUrl = (url: string): Map<string, string> => {
  const searchValue = url.indexOf('?') == -1 ? '' : url.substr(url.indexOf('?') + 1);
  const result = new Map<string, string>();
  if (!!searchValue.length) {
    const pairs = searchValue.split('&');
    pairs.forEach((pair) => {
      const keyAndValue = pair.split('=');
      if (keyAndValue.length > 0) {
        const key = keyAndValue[0];
        const value = keyAndValue.length > 1 ? keyAndValue[1] : '';
        result.set(key, value);
      }
    });
  }
  return result;
};

export const hasSelection = () => {
  const selection = window?.getSelection()?.toString() || '';
  return selection?.length > 0;
};

export const scrollToElement = (parentId: string, offset: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.getElementById(parentId).scroll({ top: offset, behavior: 'smooth' });
};
