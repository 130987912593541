import React from 'react';
import { Headline, Paragraph } from 'components/common';
import AuthFormWrapper from '../authFormWrapper';
import { Props } from './type';
import { getLogoWhite } from 'utils/styleUtils';

import styles from './AuthPageWrap.module.scss';
import { useTrackedSelector } from 'components/app/store';

const AuthPageWrap = ({
  title,
  subtitle,
  children,
  linkText,
  linkHref,
  hintMessage,
  withBottomLine,
}: Props): JSX.Element => {
  const {
    auth: { whitelabel },
  } = useTrackedSelector();

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        {!!whitelabel?.logoContent?.length ? <img src={whitelabel.logoContent} alt="logo" /> : getLogoWhite()}
      </div>

      <div className={styles.contentContainer}>
        <AuthFormWrapper
          linkText={linkText}
          linkHref={linkHref}
          hintMessage={hintMessage}
          withBottomLine={withBottomLine}
        >
          {/* todo better to classnames with conditions so that all styles are in the in the styles file */}
          <div className={styles.titleContainer}>
            <Headline className={styles.title} size="medium">
              {title}
            </Headline>
            {!!subtitle && (
              <Paragraph size="medium" className={styles.subtitle}>
                {subtitle}
              </Paragraph>
            )}
          </div>
          {children}
        </AuthFormWrapper>
      </div>
    </div>
  );
};

export default AuthPageWrap;
