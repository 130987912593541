import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { RegisterUser } from 'types/inputTypes';
import { URL_QUERY_PARAM_CODE, URL_QUERY_PARAM_KEY } from 'utils/routingUtils';
import logger from 'utils/logger/logger';
import { ContentType } from 'types/contentTypes';

/**
 * Sends a POST request to the BE to register a new user.
 */
export const register = (data: RegisterUser): Promise<boolean> => {
  return axios
    .post('api/user', JSON.stringify(data), {
      headers: {
        'Content-Type': ContentType.APPLICATION_JSON,
      },
    })
    .then((response) => {
      if (response.status === StatusCodes.CREATED) {
        return true;
      } else {
        logger.error(`User registration result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch(() => {
      logger.error(`User registration error happened`);
      return false;
    });
};

/**
 * Sends a GET request to the BE to confirm the email of a newly registered user.
 */
export const confirmEmail = (key: string): Promise<boolean> => {
  return axios
    .get(`/api/user/email?${URL_QUERY_PARAM_KEY}=${key}`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Email confirmation result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch(() => {
      logger.error(`Email confirmation error happened`);
      return false;
    });
};

/**
 * Sends a POST request to the BE to trigger another confirmation email for the currently logged in user.
 */
export const triggerConfirmationEmail = (): Promise<boolean> => {
  return axios
    .post('/api/user/email')
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Email confirmation triggering result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Email confirmation triggering error happened: ${error}`);
      return false;
    });
};

/**
 * Sends a DELETE request to the BE to initialize a password reset process.
 */
export const initializePasswordReset = (email: string): Promise<boolean> => {
  return axios
    .patch(
      'api/user/password',
      {
        email: email,
      },
      {
        headers: {
          'Content-Type': ContentType.APPLICATION_JSON,
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Password reset init result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch(() => {
      logger.error(`Password reset init error happened`);
      return false;
    });
};

/**
 * Sends a GET request to the BE to verify if a password reset is possible with this key.
 * Checks if the key is not expired.
 *
 * @return true if the key is still valid, false otherwise
 */
export const verifyPasswordResetKey = (key: string): Promise<boolean> => {
  return axios
    .get(`/api/user/password?${URL_QUERY_PARAM_KEY}=${key}`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Password reset key verification result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch(() => {
      logger.error(`Password reset key verification error happened`);
      return false;
    });
};

/**
 * Sends a POST request to the BE to reset a password to a new value.
 */
export const resetPassword = (password: string, key: string): Promise<boolean> => {
  return axios
    .post(
      `/api/user/password?${URL_QUERY_PARAM_KEY}=${key}`,
      {
        password: password,
      },
      {
        headers: {
          'Content-Type': ContentType.APPLICATION_JSON,
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Password reset result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch(() => {
      logger.error(`Password reset error happened`);
      return false;
    });
};

/**
 * Sends a GET request to the BE to identify an email address by invitation code.
 */
export const getEmailByInvitationCode = (code: string): Promise<string | null> => {
  return axios
    .get(`/api/user/invitation/${code}/email`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return response.data;
      } else {
        logger.error(`Get email by invitation code result is not OK: ${response.status}`);
        return null;
      }
    })
    .catch(() => {
      logger.error(`Get email by invitation code error happened`);
      return null;
    });
};

/**
 * Sends a GET request to the BE to verify if an invitation code is still not used, i.e. valid.
 *
 * @return true if the code is still valid, false otherwise
 */
export const verifyInvitationCode = (key: string): Promise<boolean> => {
  return axios
    .get(`/api/user/invitation?${URL_QUERY_PARAM_CODE}=${key}`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Invitation key verification result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch(() => {
      logger.error(`Invitation key verification error happened`);
      return false;
    });
};

/**
 * Sends a GET request to the BE to delete the account.
 *
 * @return true if deletion was successful, false otherwise
 */
export const deleteUser = (key: string): Promise<boolean> => {
  return axios
    .delete(`/api/user?${URL_QUERY_PARAM_KEY}=${key}`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`User deletion result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch(() => {
      logger.error(`User deletion error happened`);
      return false;
    });
};
