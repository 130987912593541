import React, { useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import VerticalTabsTitles from '../verticalTabsTitles';
import { Props } from './type';

import styles from './VerticalTabs.module.scss';

export const VerticalTabs = ({ controlTabs, queryKey }: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const navigateTo = useNavigate();
  const queryParams = useQueryParams();

  const tabs = useMemo(
    () =>
      controlTabs.map((ct, index) => ({
        id: ct.id,
        active: index == activeTabIndex,
        title: ct.title,
      })),
    [controlTabs, activeTabIndex],
  );

  useEffect(() => {
    if (queryKey) {
      const queryParam = queryParams.get(queryKey);
      if (queryParam) {
        const tabIndex = controlTabs.findIndex((tab) => tab.id === queryParam);
        setActiveTabIndex(tabIndex >= 0 ? tabIndex : 0);
      } else {
        setActiveTabIndex(0);
      }
    }
  }, [controlTabs, queryParams, queryKey]);

  const handleControlButtonClick = (id: string) => {
    if (queryKey) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const routeQueryParams: any = {};
      for (const entry of queryParams.entries()) {
        routeQueryParams[entry[0]] = entry[1];
      }
      routeQueryParams[queryKey] = id;
      navigateTo({ search: `?${createSearchParams(routeQueryParams)}` });
    } else {
      setActiveTabIndex(tabs.findIndex((tab) => tab.id === id));
    }
  };

  return (
    <div className={styles.mainContainer}>
      <VerticalTabsTitles className={styles.tabTitles} tabs={tabs} onTabClick={handleControlButtonClick} />
      <div className={styles.content}>{controlTabs[activeTabIndex].content}</div>
    </div>
  );
};

export default VerticalTabs;
