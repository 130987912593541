import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import styles from './CreateAddModeSelectionModal.module.scss';
import { Button } from 'components/common';

interface Props {
  onHide: () => void;
  onWithSizes: () => void;
  onWithoutSizes: () => void;
}

export default function CreateAdModeSelectionModal({ onHide, onWithSizes, onWithoutSizes }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Modal open={true} onCancel={onHide} className={styles.modal} footer={null} maskClosable={false} width={1100}>
      <div className={styles.modalHeader}>
        <div className={styles.title}>{t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.ADD')}</div>
      </div>
      <div className={styles.body}>
        {[
          {
            title: t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.UPLOAD_ADS'),
            desc: t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.UPLOAD_ADS_DESC'),
            callback: onWithoutSizes,
          },
          {
            title: t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.UPLOAD_ADS_IN_SIZES'),
            desc: t('PRIVATE.USER.DASHBOARD.PROJECT_VIEW.UPSERT_AD.UPLOAD_ADS_IN_SIZES_DESC'),
            callback: onWithSizes,
          },
        ].map(({ title, desc, callback }) => (
          <div key={title} className={styles.card} onClick={() => callback()}>
            <div className={styles.title}>{title}</div>
            <div className={styles.desc}>{desc}</div>
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <Button className={styles.button} type="button" buttonType="secondary" onClick={onHide}>
          {t('COMMON.CANCEL')}
        </Button>
      </div>
    </Modal>
  );
}
