import React, { useContext } from 'react';
import { Modal } from 'antd';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/img/close.svg';
import { ModalContext, ModalContextProps } from 'contextProviders/ModalProvider';
import Button from '../button';
import CustomLink from '../link';

import styles from './Modal.module.scss';

const ModalPortal = (): JSX.Element | null => {
  const { t } = useTranslation();

  const DEFAULT_VARIANT_CANCEL = 'secondary';
  const DEFAULT_VARIANT_SUBMIT = 'primary';
  const DEFAULT_LINK_REFERENCE = '';
  const DEFAULT_TITLE_CANCEL = t('COMMON.CANCEL');
  const DEFAULT_TITLE_SUBMIT = t('COMMON.SAVE');
  const DEFAULT_LINK_TITLE = t('COMMON.LINK');

  const {
    context = true,
    isOpened,
    modalContent,
    headerContent,
    bodyContent,
    footerContent,
    toggleModal,
    onHide,
    onSubmit,
    onCancel,
    options: {
      modal: { className: modalClassName = '' } = {},
      header: { title: headerTitle = '', closeButton = true, className: headerClassName = '' } = {},
      body: { className: bodyClassName = '' } = {},
      footer: {
        titleCancel = DEFAULT_TITLE_CANCEL,
        variantCancel = DEFAULT_VARIANT_CANCEL,
        showCancel = true,
        titleSubmit = DEFAULT_TITLE_SUBMIT,
        variantSubmit = DEFAULT_VARIANT_SUBMIT,
        showSubmit = true,
        showLink = true,
        linkTitle = DEFAULT_LINK_TITLE,
        linkReference = DEFAULT_LINK_REFERENCE,
        className: footerClassName = {},
      } = {},
    } = {},
  } = useContext(ModalContext) as ModalContextProps;

  if (!context || !isOpened) {
    return null;
  }

  if (modalContent) {
    return ReactDOM.createPortal(modalContent, context);
  }

  const handlerHide = () => toggleModal(false);

  const defaultHeaderContent = (
    <div className={styles.modalHeader}>
      <h4>{headerTitle}</h4>
      {closeButton && (
        <div className={styles.closeIconContainer} onClick={onHide || handlerHide}>
          <CloseIcon />
        </div>
      )}
    </div>
  );

  const defaultBodyContent = <></>;

  const defaultFooterContent = (
    <div className={styles.modalFooter}>
      {showLink && (
        <div>
          <CustomLink href={linkReference}>{linkTitle}</CustomLink>
        </div>
      )}
      <div className={styles.buttonContainer}>
        {showCancel && (
          <Button onClick={onCancel || onHide} buttonType={variantCancel}>
            {titleCancel}
          </Button>
        )}
        {showSubmit && (
          <Button onClick={onSubmit} buttonType={variantSubmit}>
            {titleSubmit}
          </Button>
        )}
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    <Modal
      open={isOpened}
      onOk={onSubmit}
      onCancel={onHide || handlerHide}
      className={styles.modalContainer}
      closable={false}
      footer={[
        <div key="footer" className={footerClassName}>
          {footerContent ?? defaultFooterContent}
        </div>,
      ]}
    >
      <div className={modalClassName}>
        <div className={headerClassName}> {headerContent ?? defaultHeaderContent}</div>
        <div className={bodyClassName}> {bodyContent ?? defaultBodyContent}</div>
      </div>
    </Modal>,
    context,
  );
};

export default ModalPortal;
