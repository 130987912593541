import isEqual from 'lodash/isEqual';

/**
 * Allows to compare two objects by providing keys to ignore.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepEqual = (x?: any | null, y?: any | null, ignoreRootProps?: Set<string>): boolean => {
  if (x == null || y == null) return x === y;

  const keys = Object.keys(x);
  if (!isEqual(keys, Object.keys(y))) return false;

  for (const key of keys) {
    if (ignoreRootProps && ignoreRootProps.has(key)) continue;
    if (!isEqual(x[key], y[key])) return false;
  }
  return true;
};
