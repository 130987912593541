const ButtonsKeyCodes = {
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
  ctrl: 17,
  alt: 18,
  pause: 19,
  break: 19,
  capslock: 20,
  escape: 27,
  space: 32,
  pageup: 33,
  pagedown: 34,
  end: 35,
  home: 36,
  arrowleft: 37,
  arrowup: 38,
  arrowright: 39,
  arrowdown: 40,
  printscreen: 44,
  insert: 45,
  delete: 46,
  '0': 48,
  '1': 49,
  '2': 50,
  '3': 51,
  '4': 52,
  '5': 53,
  '6': 54,
  '7': 55,
  '8': 56,
  '9': 57,
  a: 65,
  b: 66,
  c: 67,
  d: 68,
  e: 69,
  f: 70,
  g: 71,
  h: 72,
  i: 73,
  j: 74,
  k: 75,
  l: 76,
  m: 77,
  n: 78,
  o: 79,
  p: 80,
  q: 81,
  r: 82,
  s: 83,
  t: 84,
  u: 85,
  v: 86,
  w: 87,
  x: 88,
  y: 89,
  z: 90,
  left_window_key: 91,
  right_window_key: 92,
  context_menu: 93,
  numpad_0: 96,
  numpad_1: 97,
  numpad_2: 98,
  numpad_3: 99,
  numpad_4: 100,
  numpad_5: 101,
  numpad_6: 102,
  numpad_7: 103,
  numpad_8: 104,
  numpad_9: 105,
  multiply: 106,
  add: 107,
  plus: 107,
  subtract: 109,
  minus: 109,
  decimal_point: 110,
  divide: 111,
  f1: 112,
  f2: 113,
  f3: 114,
  f4: 115,
  f5: 116,
  f6: 117,
  f7: 118,
  f8: 119,
  f9: 120,
  f10: 121,
  f11: 122,
  f12: 123,
  numlock: 144,
  scrolllock: 145,
  audio_volume_mute: 173,
  audio_volume_down: 174,
  audio_volume_up: 175,
  media_player: 181,
  launch_application_1: 1,
  launch_application_2: 2,
  semicolon: 186,
  equalsign: 187,
  comma: 188,
  dash: 189,
  period: 190,
  forward_slash: 191,
  back_quote: 192,
  open_bracket: 219,
  backslash: 220,
  close_bracket: 221,
  single_quote: 222,
};

export default ButtonsKeyCodes;
