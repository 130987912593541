import React, { useEffect, useState } from 'react';
import clsx from 'classnames';
import { ReactComponent as ChevronIcon } from 'assets/img/chevron.svg';
import OutsideClick from '../outSideClick';
import DropDownMenu from '../dropdownMenu';
import { Variant } from '../dropdownMenu/types';
import { Props } from './type';

import styles from './DropdownInput.module.scss';
import { useTranslation } from 'react-i18next';

const DropDownInput = ({
  variants,
  disabled,
  takeValue,
  initialValue,
  className,
  dropDownWrapperClassname,
  menuContainerClassname,
  icon = <ChevronIcon />,
  ...rest
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [openDropdown, setIsOpened] = useState<boolean>(false);
  const [chosenValue, setChosenValue] = useState<string>('');

  const openDropDown = () => {
    if (!disabled) {
      setIsOpened(!openDropdown);
    }
  };

  const chooseValue = (arg: Variant) => {
    setIsOpened(!openDropdown);
    setChosenValue(arg.id);
    takeValue(arg.name, arg.id);
  };

  const containerStyle = clsx(styles.container, {
    [`${className}`]: !!className,
  });

  const dropDownStyle = clsx(styles.dropDownWrapper, dropDownWrapperClassname, {
    [styles.focused]: openDropdown,
    [styles.disabled]: disabled,
  });

  useEffect(() => {
    setChosenValue(initialValue?.id || '');
  }, [initialValue]);

  return (
    <div className={containerStyle} {...rest}>
      <OutsideClick onOutsideClick={() => setIsOpened(false)}>
        <div className={styles.container} onClick={openDropDown}>
          <div className={dropDownStyle}>
            <p className={clsx({ [styles.placeholder]: !chosenValue.length })}>
              {chosenValue === ''
                ? t('COMMON.SELECT')
                : variants.find((i) => i.id === chosenValue)?.name || t('COMMON.SELECT')}
            </p>
            <span className={styles.icon}>{icon}</span>
          </div>
        </div>
        {!disabled && variants.length > 0 && (
          <div className={styles.menu}>
            <DropDownMenu
              open={openDropdown}
              variants={variants}
              chosenValue={chosenValue}
              chooseValue={chooseValue}
              containerClassname={menuContainerClassname}
            />
          </div>
        )}
      </OutsideClick>
    </div>
  );
};

export default DropDownInput;
