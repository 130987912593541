import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectViewLayout, User, Whitelabel } from 'types/types.d';

export type AuthSliceState = {
  user: User | null;
  projectViewLayout: ProjectViewLayout; // keeping separately from the user to have fewer reloads due to deps
  whitelabel: Whitelabel | null;
  adminUserEmail: string | null; // admin can view this user's data on all admin pages
};

const initialState = {
  user: null,
  projectViewLayout: ProjectViewLayout.GRID,
  adminUserEmail: null,
} as AuthSliceState;

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    userLoaded(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    projectViewLayoutSet(state, action: PayloadAction<ProjectViewLayout>) {
      state.projectViewLayout = action.payload;
    },
    whitelabelSet(state, action: PayloadAction<Whitelabel>) {
      state.whitelabel = action.payload;
    },
    adminUserEmailSet(state, action: PayloadAction<string>) {
      state.adminUserEmail = action.payload;
    },
    adminUserEmailCleared(state) {
      state.adminUserEmail = null;
    },
  },
});

export const { userLoaded, projectViewLayoutSet, whitelabelSet, adminUserEmailSet, adminUserEmailCleared } =
  authSlice.actions;

export default authSlice.reducer;
