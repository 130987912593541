import {
  AdDnaAuthority,
  AdDnaCopyHookFraming,
  AdDnaCopyHookRelevance,
  AdDnaCopyHookTypology,
  AdDnaCtaPlacement,
  AdDnaFinancialBenefit,
  AdDnaInstantValue,
  AdDnaLayout,
  AdDnaLossAversion,
  AdDnaPopularFormat,
  AdDnaReferences,
  AdDnaRole,
  AdDnaScarcity,
  AdDnaSimpleLanguage,
  AdDnaSingleAssetFormatType,
  AdDnaSingleAssetSubType,
  AdDnaSingleAssetType,
  AdDnaSocialProof,
  AdDnaSound,
  AdDnaTrend,
  AdDnaUgcCreators,
  AdDnaVisualFeature,
  AdDnaVisualHookRelevance,
  AdType,
} from 'types/businessTypes';
import { ClientPaymentModel, ClientPersona, Maybe, Scalars, SocialMediaChannel } from './types.d';

export type SearchPaging = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type Sorting = {
  orderBy: OrderBy;
};

export enum OrderBy {
  EmailAsc = 'EmailAsc',
  EmailDesc = 'EmailDesc',
  FirstNameAsc = 'FirstNameAsc',
  FirstNameDesc = 'FirstNameDesc',
  LastNameAsc = 'LastNameAsc',
  LastNameDesc = 'LastNameDesc',
  LanguageAsc = 'LanguageAsc',
  LanguageDesc = 'LanguageDesc',
  IdAsc = 'IdAsc',
  IdDesc = 'IdDesc',
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
  UsedDateAsc = 'UsedDateAsc',
  UsedDateDesc = 'UsedDateDesc',
  CreatedDateAsc = 'CreatedDateAsc',
  CreatedDateDesc = 'CreatedDateDesc',
  LastModifiedDateAsc = 'LastModifiedDateAsc',
  LastModifiedDateDesc = 'LastModifiedDateDesc',
  MarketAsc = 'MarketAsc',
  MarketDesc = 'MarketDesc',
  TitleAsc = 'TitleAsc',
  TitleDesc = 'TitleDesc',
  RoleAsc = 'RoleAsc',
  RoleDesc = 'RoleDesc',
  PaymentModelAsc = 'PaymentModelAsc',
  PaymentModelDesc = 'PaymentModelDesc',
  ChannelAsc = 'ChannelAsc',
  ChannelDesc = 'ChannelDesc',
  UgcAsc = 'UgcAsc',
  UgcDesc = 'UgcDesc',
  // ProductFeaturesAsc = 'ProductFeaturesAsc',
  // ProductFeaturesDesc = 'ProductFeaturesDesc',
  // ValuePropositionsAsc = 'ValuePropositionsAsc',
  // ValuePropositionsDesc = 'ValuePropositionsDesc',
  // PersonasAsc = 'PersonasAsc',
  // PersonasDesc = 'PersonasDesc',
  LayoutAsc = 'LayoutAsc',
  LayoutDesc = 'LayoutDesc',
  VideoLengthAsc = 'VideoLengthAsc',
  VideoLengthDesc = 'VideoLengthDesc',
  SlidesNumberAsc = 'SlidesNumberAsc',
  SlidesNumberDesc = 'SlidesNumberDesc',
  // VisualFeaturesAsc = 'VisualFeaturesAsc',
  // VisualFeaturesDesc = 'VisualFeaturesDesc',
  AccountManagerAsc = 'AccountManagerAsc',
  AccountManagerDesc = 'AccountManagerDesc',
  CreativeStrategistAsc = 'CreativeStrategistAsc',
  CreativeStrategistDesc = 'CreativeStrategistDesc',
  CopywriterAsc = 'CopywriterAsc',
  CopywriterDesc = 'CopywriterDesc',
  GraphicDesignerAsc = 'GraphicDesignerAsc',
  GraphicDesignerDesc = 'GraphicDesignerDesc',
  MotionDesignerAsc = 'MotionDesignerAsc',
  MotionDesignerDesc = 'MotionDesignerDesc',
  IllustratorAsc = 'IllustratorAsc',
  IllustratorDesc = 'IllustratorDesc',
  ModelAsc = 'ModelAsc',
  ModelDesc = 'ModelDesc',
  PhotographerAsc = 'PhotographerAsc',
  PhotographerDesc = 'PhotographerDesc',
  VideographerAsc = 'VideographerAsc',
  VideographerDesc = 'VideographerDesc',
  CreatorAsc = 'CreatorAsc',
  CreatorDesc = 'CreatorDesc',
  CtaAsc = 'CtaAsc',
  CtaDesc = 'CtaDesc',
  DomainAsc = 'DomainAsc',
  DomainDesc = 'DomainDesc',
  InsightsMetaSpendAsc = 'InsightsMetaSpendAsc',
  InsightsMetaSpendDesc = 'InsightsMetaSpendDesc',
  InsightsMetaAppInstallAsc = 'InsightsMetaAppInstallAsc',
  InsightsMetaAppInstallDesc = 'InsightsMetaAppInstallDesc',
  InsightsMetaAppInstallCostAsc = 'InsightsMetaAppInstallCostAsc',
  InsightsMetaAppInstallCostDesc = 'InsightsMetaAppInstallCostDesc',
  InsightsMetaViewContentAsc = 'InsightsMetaViewContentAsc',
  InsightsMetaViewContentDesc = 'InsightsMetaViewContentDesc',
  InsightsMetaViewContentCostAsc = 'InsightsMetaViewContentCostAsc',
  InsightsMetaViewContentCostDesc = 'InsightsMetaViewContentCostDesc',
  InsightsMetaCompleteRegistrationAsc = 'InsightsMetaCompleteRegistrationAsc',
  InsightsMetaCompleteRegistrationDesc = 'InsightsMetaCompleteRegistrationDesc',
  InsightsMetaCompleteRegistrationCostAsc = 'InsightsMetaCompleteRegistrationCostAsc',
  InsightsMetaCompleteRegistrationCostDesc = 'InsightsMetaCompleteRegistrationCostDesc',
  InsightsMetaPurchaseAsc = 'InsightsMetaPurchaseAsc',
  InsightsMetaPurchaseDesc = 'InsightsMetaPurchaseDesc',
  InsightsMetaPurchaseCostAsc = 'InsightsMetaPurchaseCostAsc',
  InsightsMetaPurchaseCostDesc = 'InsightsMetaPurchaseCostDesc',
  InsightsMetaReturnOnAdSpendAsc = 'InsightsMetaReturnOnAdSpendAsc',
  InsightsMetaReturnOnAdSpendDesc = 'InsightsMetaReturnOnAdSpendDesc',
  InsightsMetaReturnOnAdSpendCostAsc = 'InsightsMetaReturnOnAdSpendCostAsc',
  InsightsMetaReturnOnAdSpendCostDesc = 'InsightsMetaReturnOnAdSpendCostDesc',
  InsightsTiktokSpendAsc = 'InsightsTiktokSpendAsc',
  InsightsTiktokSpendDesc = 'InsightsTiktokSpendDesc',
  InsightsTiktokAppInstallAsc = 'InsightsTiktokAppInstallAsc',
  InsightsTiktokAppInstallDesc = 'InsightsTiktokAppInstallDesc',
  InsightsTiktokAppInstallCostAsc = 'InsightsTiktokAppInstallCostAsc',
  InsightsTiktokAppInstallCostDesc = 'InsightsTiktokAppInstallCostDesc',
  InsightsTiktokViewContentAsc = 'InsightsTiktokViewContentAsc',
  InsightsTiktokViewContentDesc = 'InsightsTiktokViewContentDesc',
  InsightsTiktokViewContentCostAsc = 'InsightsTiktokViewContentCostAsc',
  InsightsTiktokViewContentCostDesc = 'InsightsTiktokViewContentCostDesc',
  InsightsTiktokAddToCartAsc = 'InsightsTiktokAddToCartAsc',
  InsightsTiktokAddToCartDesc = 'InsightsTiktokAddToCartDesc',
  InsightsTiktokAddToCartCostAsc = 'InsightsTiktokViewContentCostAsc',
  InsightsTiktokAddToCartCostDesc = 'InsightsTiktokAddToCartCostDesc',
  InsightsTiktokAddToWishlistAsc = 'InsightsTiktokAddToWishlistAsc',
  InsightsTiktokAddToWishlistDesc = 'InsightsTiktokAddToWishlistDesc',
  InsightsTiktokAddToWishlistCostAsc = 'InsightsTiktokAddToWishlistCostAsc',
  InsightsTiktokAddToWishlistCostDesc = 'InsightsTiktokAddToWishlistCostDesc',
  InsightsTiktokCompleteRegistrationAsc = 'InsightsTiktokCompleteRegistrationAsc',
  InsightsTiktokCompleteRegistrationDesc = 'InsightsTiktokCompleteRegistrationDesc',
  InsightsTiktokCompleteRegistrationCostAsc = 'InsightsTiktokCompleteRegistrationCostAsc',
  InsightsTiktokCompleteRegistrationCostDesc = 'InsightsTiktokCompleteRegistrationCostDesc',
  InsightsTiktokPurchaseAsc = 'InsightsTiktokPurchaseAsc',
  InsightsTiktokPurchaseDesc = 'InsightsTiktokPurchaseDesc',
  InsightsTiktokPurchaseCostAsc = 'InsightsTiktokPurchaseCostAsc',
  InsightsTiktokPurchaseCostDesc = 'InsightsTiktokPurchaseCostDesc',
  AdScoreAsc = 'AdScoreAsc',
  AdScoreDesc = 'AdScoreDesc',
  AdScoreMetaAsc = 'AdScoreMetaAsc',
  AdScoreMetaDesc = 'AdScoreMetaDesc',
  AdScoreTiktokAsc = 'AdScoreTiktokAsc',
  AdScoreTiktokDesc = 'AdScoreTiktokDesc',
  ShareAsc = 'ShareAsc',
  ShareDesc = 'ShareDesc',
}

export type RegisterUser = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  invitationCode: Scalars['String'];
  whitelabel?: Maybe<Scalars['String']>;
};

export type UserSearchCriteria = {
  email?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
};

export type InvitationSearchCriteria = {
  code?: Maybe<Scalars['String']>;
};

export type ClientSearchCriteria = {
  name?: Maybe<Scalars['String']>;
};

export type UserNotificationSettingInput = {
  type: Scalars['String'];
  email: Scalars['Boolean'];
  slack: Scalars['Boolean'];
};

export type ProjectSearchCriteria = {
  clientId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BriefSearchCriteria = {
  projectId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AdSearchCriteria = {
  projectId?: Maybe<Scalars['String']>;
  type?: AdType;
  title?: Maybe<Scalars['String']>;
  internalState?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type ProjectInput = {
  clientId: Scalars['String'];

  id?: Maybe<Scalars['String']>;
  paymentModel: ClientPaymentModel;
  title: Scalars['String'];
  deliveryDate: Scalars['String'];
  ugc: Scalars['Boolean'];

  briefId?: Maybe<Scalars['String']>;
};

export type AdDnaSearchCriteria = {
  clientIds?: Maybe<Array<Scalars['String']>>;
  projectId?: Maybe<Scalars['String']>;

  pgId?: Maybe<Scalars['String']>;
  pgIdAuthor?: Maybe<Scalars['String']>;

  // *************** MAIN ***************
  title?: Maybe<Scalars['String']>;
  paymentModel?: Maybe<ClientPaymentModel>;
  channel?: Maybe<SocialMediaChannel>;
  layout?: Maybe<AdDnaLayout>;
  assetRatio?: Maybe<Scalars['String']>;
  assetFormat?: Maybe<AdType>;
  slidesNumber?: Maybe<Scalars['Int']>;
  videoLength?: Maybe<Scalars['Int']>;

  ctaPlacement?: Maybe<AdDnaCtaPlacement>;

  // *************** SCRIPT/COPY ***************
  copyPlain?: Maybe<Scalars['String']>;
  scriptPlain?: Maybe<Scalars['String']>;

  // *************** AD SCORE ***************
  adScore?: Maybe<Scalars['Float']>;
  adScoreMeta?: Maybe<Scalars['Float']>;
  adScoreTiktok?: Maybe<Scalars['Float']>;
};

export type AdDnaUpdateRequest = {
  // *************** MAIN ***************
  channel?: Maybe<SocialMediaChannel>;
  singleAssetFormatType?: Maybe<AdDnaSingleAssetFormatType>;
  singleAssetType?: Maybe<AdDnaSingleAssetType>;
  singleAssetSubType?: Maybe<AdDnaSingleAssetSubType>;
  languages?: Maybe<Array<Scalars['String']>>;
  layout?: Maybe<AdDnaLayout>;

  // ************** GRAB ATTENTION ***************
  visualHookBranded?: Maybe<Scalars['Boolean']>;
  visualHookFeatures?: Maybe<Array<AdDnaVisualFeature>>;
  visualHookUnusual?: Maybe<Scalars['Boolean']>;
  visualHookRelevance?: Maybe<Array<AdDnaVisualHookRelevance>>;
  copyHook?: Maybe<Scalars['String']>;
  copyHookPlain?: Maybe<Scalars['String']>;
  copyHookFraming?: Maybe<Array<AdDnaCopyHookFraming>>;
  copyHookTypology?: Maybe<Array<AdDnaCopyHookTypology>>;
  copyHookRelevance?: Maybe<Array<AdDnaCopyHookRelevance>>;
  popularFormats?: Maybe<Array<AdDnaPopularFormat>>;
  references?: Maybe<Array<AdDnaReferences>>;
  trend?: Maybe<Array<AdDnaTrend>>;
  sound?: Maybe<Array<AdDnaSound>>;
  aiContent?: Maybe<Scalars['Boolean']>;

  // ************** HOLD ATTENTION ***************
  ugcCreators?: Maybe<Array<AdDnaUgcCreators>>;
  visualBranded?: Maybe<Scalars['Boolean']>;
  visualFeatures?: Maybe<Array<AdDnaVisualFeature>>;
  copy?: Maybe<Scalars['String']>;
  copyPlain?: Maybe<Scalars['String']>;
  script?: Maybe<Scalars['String']>;
  scriptPlain?: Maybe<Scalars['String']>;
  painPoints?: Maybe<Array<Scalars['String']>>;
  personas?: Maybe<Array<ClientPersona>>;
  benefits?: Maybe<Array<Scalars['String']>>;
  productFeatures?: Maybe<Array<Scalars['String']>>;
  products?: Maybe<Array<Scalars['String']>>;
  valuePropositions?: Maybe<Array<Scalars['String']>>;
  educating?: Maybe<Scalars['Boolean']>;
  educatingApp?: Maybe<Scalars['Boolean']>;
  educatingAppWholeApp?: Maybe<Scalars['Boolean']>;
  educatingAppService?: Maybe<Scalars['Boolean']>;
  educatingBroadTopic?: Maybe<Scalars['Boolean']>;
  userCentricity?: Maybe<Scalars['Int']>;
  specificity?: Maybe<Scalars['Int']>;
  statistics?: Maybe<Scalars['Boolean']>;
  simpleLanguage?: Maybe<Array<AdDnaSimpleLanguage>>;
  legalDisclaimer?: Maybe<Scalars['Boolean']>;
  subtitles?: Maybe<Scalars['Boolean']>;
  entertaining?: Maybe<Scalars['Boolean']>;
  anticipation?: Maybe<Scalars['Boolean']>;

  // ************** EVOKE ACTION ***************
  financialBenefit?: Maybe<Array<AdDnaFinancialBenefit>>;
  instantValue?: Maybe<Array<AdDnaInstantValue>>;
  socialProof?: Maybe<Array<AdDnaSocialProof>>;
  authority?: Maybe<Array<AdDnaAuthority>>;
  lossAversion?: Maybe<Array<AdDnaLossAversion>>;
  scarcity?: Maybe<Array<AdDnaScarcity>>;
  unity?: Maybe<Scalars['Boolean']>;
  deeperMotivationalDesire?: Maybe<Scalars['Int']>;
  sellTheProductSellTheFilling?: Maybe<Scalars['Int']>;
  ctas?: Maybe<Array<Scalars['String']>>;
  ctaPlacement?: AdDnaCtaPlacement;

  // *************** ROLES ***************
  roles?: Maybe<Array<AdDnaRole>>;

  accountManagerUserId?: Maybe<Scalars['String']>;
  accountManagerName?: Maybe<Scalars['String']>;
  accountManagerUserIds?: Maybe<Array<Scalars['String']>>;
  accountManagerNames?: Maybe<Scalars['String']>;

  creativeStrategistUserId?: Maybe<Scalars['String']>;
  creativeStrategistName?: Maybe<Scalars['String']>;
  creativeStrategistUserIds?: Maybe<Array<Scalars['String']>>;
  creativeStrategistNames?: Maybe<Scalars['String']>;

  copywriterUserId?: Maybe<Scalars['String']>;
  copywriterName?: Maybe<Scalars['String']>;
  copywriterUserIds?: Maybe<Array<Scalars['String']>>;
  copywriterNames?: Maybe<Scalars['String']>;

  graphicDesignerUserId?: Maybe<Scalars['String']>;
  graphicDesignerName?: Maybe<Scalars['String']>;
  graphicDesignerUserIds?: Maybe<Array<Scalars['String']>>;
  graphicDesignerNames?: Maybe<Scalars['String']>;

  motionDesignerUserId?: Maybe<Scalars['String']>;
  motionDesignerName?: Maybe<Scalars['String']>;
  motionDesignerUserIds?: Maybe<Array<Scalars['String']>>;
  motionDesignerNames?: Maybe<Scalars['String']>;

  illustratorUserId?: Maybe<Scalars['String']>;
  illustratorName?: Maybe<Scalars['String']>;
  illustratorUserIds?: Maybe<Array<Scalars['String']>>;
  illustratorNames?: Maybe<Scalars['String']>;

  modelUserId?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  modelUserIds?: Maybe<Array<Scalars['String']>>;
  modelNames?: Maybe<Scalars['String']>;

  photographerUserId?: Maybe<Scalars['String']>;
  photographerName?: Maybe<Scalars['String']>;
  photographerUserIds?: Maybe<Array<Scalars['String']>>;
  photographerNames?: Maybe<Scalars['String']>;

  videographerUserId?: Maybe<Scalars['String']>;
  videographerName?: Maybe<Scalars['String']>;
  videographerUserIds?: Maybe<Array<Scalars['String']>>;
  videographerNames?: Maybe<Scalars['String']>;

  creatorUserId?: Maybe<Scalars['String']>;
  creatorName?: Maybe<Scalars['String']>;
  creatorUserIds?: Maybe<Array<Scalars['String']>>;
  creatorNames?: Maybe<Scalars['String']>;

  // *************** SM INSIGHTS ***************
  insightsSpend?: Maybe<Scalars['Float']>;
  insightsAppInstall?: Maybe<Scalars['Int']>;
  insightsAppInstallCost?: Maybe<Scalars['Float']>;
  insightsCompleteRegistration?: Maybe<Scalars['Int']>;
  insightsCompleteRegistrationCost?: Maybe<Scalars['Float']>;
  insightsViewContent?: Maybe<Scalars['Int']>;
  insightsViewContentCost?: Maybe<Scalars['Float']>;
  // META
  insightsMetaSpend?: Maybe<Scalars['Float']>;
  insightsMetaAppInstall?: Maybe<Scalars['Int']>;
  insightsMetaAppInstallCost?: Maybe<Scalars['Float']>;
  insightsMetaCompleteRegistration?: Maybe<Scalars['Int']>;
  insightsMetaCompleteRegistrationCost?: Maybe<Scalars['Float']>;
  insightsMetaViewContent?: Maybe<Scalars['Int']>;
  insightsMetaViewContentCost?: Maybe<Scalars['Float']>;
  // TIKTOK
  insightsTiktokSpend?: Maybe<Scalars['Float']>;
  insightsTiktokAppInstall?: Maybe<Scalars['Int']>;
  insightsTiktokAppInstallCost?: Maybe<Scalars['Float']>;
  insightsTiktokCompleteRegistration?: Maybe<Scalars['Int']>;
  insightsTiktokCompleteRegistrationCost?: Maybe<Scalars['Float']>;
  insightsTiktokPurchase?: Maybe<Scalars['Int']>;
  insightsTiktokPurchaseCost?: Maybe<Scalars['Float']>;
  insightsTiktokViewContent?: Maybe<Scalars['Int']>;
  insightsTiktokViewContentCost?: Maybe<Scalars['Float']>;

  // *************** AD SCORE ***************
  adScore?: Maybe<Scalars['Float']>;
  adScoreMeta?: Maybe<Scalars['Float']>;
  adScoreTiktok?: Maybe<Scalars['Float']>;
};
