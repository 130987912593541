import React, { useState, useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Input, Button, Paragraph, Headline, Loading } from 'components/common';
import { LOGIN } from 'utils/routingUtils';
import { emailMaxLength } from 'utils/userUtils';
import { baseErrorNotification } from 'utils/notificationUtils';
import { initializePasswordReset } from 'services/user.service';
import AuthPageWrap from '../../common/authPageWrap';
import ToasterInfo from 'components/common/toasterInfo';

import styles from './ResetPassword.module.scss';
import { getContactUsMessage } from 'utils/i18nUtils';

interface Values {
  email: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean): any | (() => any) => {
  return Yup.object().shape({
    email: validateOnSubmit
      ? Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(emailMaxLength, t('VALIDATION.EMAIL_MAX_LENGTH', { length: emailMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(emailMaxLength, t('VALIDATION.EMAIL_MAX_LENGTH', { length: emailMaxLength }))
          .optional(),
  });
};

const ResetPassword = () => {
  const { t } = useTranslation();

  const [submitTriggered, setSubmitTriggered] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const showErrorNotification = useCallback(
    (title: string) => {
      const message: string = t(getContactUsMessage());
      toast.error(<ToasterInfo type="error" title={title} description={message} />, { ...baseErrorNotification });
    },
    [t],
  );

  const handleSubmit = useCallback(
    async (values: Values, setSubmitting: (isSubmitting: boolean) => void) => {
      setSubmitTriggered(true);
      setSubmitting(true);
      try {
        const result = await initializePasswordReset(values.email);
        if (result) {
          setSuccess(result);
        } else {
          showErrorNotification(t('PUBLIC.PASSWORD_RESET.FAILED'));
          setSubmitting(false);
        }
      } catch {
        showErrorNotification(t('PUBLIC.PASSWORD_RESET.FAILED'));
        setSubmitting(false);
      }
    },
    [showErrorNotification, t],
  );

  const handleSubmitForm = useCallback(
    (values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting),
    [handleSubmit],
  );

  return (
    <AuthPageWrap
      linkText={t('PUBLIC.PASSWORD_RESET.RETURN_TO', { page: t('PUBLIC.NAVIGATION.LOG_IN') })}
      linkHref={LOGIN}
      title={!success ? t('PUBLIC.PASSWORD_RESET.TROUBLE_LOGGING') : ''}
      subtitle={!success ? t('PUBLIC.PASSWORD_RESET.GET_BACK_YOUR_ACCOUNT') : ''}
      withBottomLine={true}
    >
      <div className={styles.content}>
        {success ? (
          <div className={styles.successMessage}>
            <Headline size="medium">{t('PUBLIC.PASSWORD_RESET.CHECK_INBOX')}</Headline>
            <Paragraph size="medium" className={styles.subtitle}>
              {t('PUBLIC.PASSWORD_RESET.RESET_PASSWORD')}
            </Paragraph>
          </div>
        ) : (
          <Formik
            validationSchema={validationSchema(t, submitTriggered)}
            onSubmit={handleSubmitForm}
            initialValues={{
              email: '',
            }}
          >
            {({ handleChange, handleBlur, values, errors, isSubmitting }) => (
              <Form className={styles.content}>
                <Input
                  type="email"
                  name="email"
                  autoFocus={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email}
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small">{t('PUBLIC.PASSWORD_RESET.EMAIL_ADDRESS')}</Paragraph>
                    </div>
                  }
                />
                <div className={styles.formButton}>
                  <Button
                    className={styles.formButton}
                    type="submit"
                    disabled={!values.email || !!errors.email || isSubmitting}
                    onClick={() => setSubmitTriggered(true)}
                  >
                    {isSubmitting ? <Loading /> : t('PUBLIC.PASSWORD_RESET.SEND_LINK')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </AuthPageWrap>
  );
};

export default ResetPassword;
