import React from 'react';
import clsx from 'classnames';

import styles from './VisualFeature.module.scss';

interface Props {
  active: boolean;
  icon: JSX.Element;
  text: string;
  onClick: () => void;
}

export default function VisualFeature({ active, icon, text, onClick }: Props): JSX.Element {
  return (
    <div className={clsx(styles.feature, { [styles.active]: active })} onClick={onClick}>
      {icon}
      <div className={styles.text}>{text}</div>
    </div>
  );
}
