import React, { useContext, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { Breakpoint } from 'react-match-breakpoints';
import { TooltipContext, TooltipContextProps } from 'contextProviders/TooltipProvider';

interface TooltipWrapperProps {
  id?: string;
  place: 'top' | 'bottom' | 'left' | 'right';
  msg: string | JSX.Element;
  content: JSX.Element;
  tooltipClassName?: string;
  contentClassName?: string;
}

const TooltipWrapper = ({
  id,
  place,
  msg,
  content,
  tooltipClassName,
  contentClassName,
}: TooltipWrapperProps): JSX.Element => {
  const { setTooltipProps } = useContext(TooltipContext) as TooltipContextProps;

  const updateTooltip = useCallback(
    (show: boolean) => {
      setTooltipProps({ id: show ? id : null, place, className: tooltipClassName });
      // setTimeout(() => ReactTooltip.rebuild(), 0);
    },
    [id, place, tooltipClassName, setTooltipProps],
  );

  const normalizedMsg = useMemo(() => {
    if (typeof msg === 'string') {
      return msg;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // todo fix it, it worked in React 17
      renderToString(msg as React.ReactElement<string | React.JSXElementConstructor<string>>);
    }
  }, [msg]);

  return (
    <>
      {/*
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore */}
      <Breakpoint.mobileAndTablet>
        <span className={contentClassName}>{content}</span>
      </Breakpoint.mobileAndTablet>
      {/*
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore */}
      <Breakpoint.desktop>
        <span
          className={contentClassName}
          data-for={id}
          data-tip={normalizedMsg}
          data-html={true}
          onMouseEnter={() => updateTooltip(true)}
          onMouseLeave={() => updateTooltip(false)}
        >
          {content}
        </span>
      </Breakpoint.desktop>
    </>
  );
};

export default TooltipWrapper;
