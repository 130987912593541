import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import logger from 'utils/logger/logger';
import { ContentType } from 'types/contentTypes';
import { ImageKitFileDetails } from 'types/businessTypes';

/**
 * Uploads a file to ImageKit via form submission.
 *
 * @return result of the upload
 */
export const uploadToImageKit = ({
  file,
  fileName,
  folder,
  useUniqueFileName,
  publicKey,
  signature,
  expire,
  token,
}: {
  file: string;
  fileName: string;
  folder: string;
  useUniqueFileName: boolean;
  publicKey: string;
  signature: string;
  expire: string;
  token: string;
}): Promise<ImageKitFileDetails | undefined | null> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', fileName);
  formData.append('folder', folder);
  formData.append('useUniqueFileName', `${useUniqueFileName}`);
  formData.append('publicKey', publicKey);
  formData.append('signature', signature);
  formData.append('expire', expire);
  formData.append('token', token);

  return axios
    .post('https://upload.imagekit.io/api/v1/files/upload', formData, {
      headers: { 'Content-Type': ContentType.MULTIPART_FORM_DATA },
    })
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return response.data;
      } else {
        logger.error(`Upload to imagekit result is not OK: ${response.status}`);
        return null;
      }
    })
    .catch((error) => {
      logger.error(`Upload to imagekit error happened: ${error}`);
      return null;
    });
};
