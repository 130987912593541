import React from 'react';
import clsx from 'classnames';
import Button from '../button';
import { Props } from './type';

import styles from './VerticalTabsTitles.module.scss';

export const VerticalTabsTitles = ({ className, tabs, onTabClick, ...res }: Props): JSX.Element => {
  return (
    <div className={clsx(styles.mainContainer, className)} {...res}>
      {tabs.map((tab) => (
        <Button
          key={tab.id}
          className={clsx(styles.controlButton, { [styles.active]: tab.active })}
          onClick={() => onTabClick(tab.id)}
          type="button"
          size="normal"
        >
          {tab.title}
        </Button>
      ))}
    </div>
  );
};

export default VerticalTabsTitles;
