import { CSSProperties, FC, memo } from 'react';
import { useDrop } from 'react-dnd';

export interface BinProps {
  accept: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrop: (item: any, monitor: any) => void;
  content: JSX.Element | string | undefined;
}

const COLOR_ACTIVE = 'rgba(64, 157, 244, 0.5)';

export const Bin: FC<BinProps> = memo(function Bin({ accept, onDrop, content }) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  const style = isActive
    ? ({
        backgroundColor: COLOR_ACTIVE,
        borderRadius: '4px',
        // height: '2rem',
        // paddingTop: '0.5rem',
        // paddingBottom: '0.5rem',
      } as CSSProperties)
    : {};

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div ref={drop} role="Bin" style={{ ...style }}>
      {content}
    </div>
  );
});
