import React from 'react';
import clsx from 'classnames';
import { Props } from './type';

import styles from './HeadLine.module.scss';

const Headline = ({ size = 'medium', className, children, ...rest }: Props): JSX.Element => {
  const headlineStyle = clsx({
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
    [styles.extraLarge]: size === 'extraLarge',
    [`${className}`]: !!className,
  });

  return (
    <h1 {...rest} className={headlineStyle}>
      {children}
    </h1>
  );
};

export default Headline;
