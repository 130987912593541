import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import logger from 'utils/logger/logger';
import { ContentType } from 'types/contentTypes';

/**
 * @return an object indicating if login request was OK and 2FA flow must take place
 */
export const login = (email: string, password: string, rememberMe: boolean): Promise<boolean> => {
  return axios
    .post(
      '/api/auth/login',
      {
        email: email,
        password: password,
        rememberMe: rememberMe,
      },
      {
        headers: {
          'Content-Type': ContentType.APPLICATION_JSON,
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Login result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Login error happened: ${error}`);
      return false;
    });
};

/**
 * Logs in secretly as another user.
 *
 * @return an object indicating if login request was OK
 */
export const secretLogin = (email: string): Promise<boolean> => {
  return axios
    .post(
      '/api/auth/login/secret',
      {
        email: email,
      },
      {
        headers: {
          'Content-Type': ContentType.APPLICATION_JSON,
        },
      },
    )
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Secret login result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Secret login error happened: ${error}`);
      return false;
    });
};

/**
 * @return true in any case because the actual result is not important to the user
 */
export const logout = (): Promise<boolean> => {
  return axios
    .delete('/api/auth/logout')
    .then(() => {
      return true;
    })
    .catch((error) => {
      logger.error(`Logout error happened: ${error}`);
      return true;
    });
};

/**
 * Sends a request to get new tokens (in cookies) based on the provided on the current token cookie
 * @return true if refresh request was OK, false otherwise
 */
export const refresh = (): Promise<boolean> => {
  return axios
    .post('/api/auth/refresh')
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return true;
      } else {
        logger.error(`Refresh result is not OK: ${response.status}`);
        return false;
      }
    })
    .catch((error) => {
      logger.error(`Refresh error happened: ${error}`);
      return false;
    });
};

/**
 * Sends a request to get auth params for client-side file upload
 * @return true if refresh request was OK, false otherwise
 */
export const authImageKit = (): Promise<{ token: string; expire: string; signature: string } | null> => {
  return axios
    .get('/api/imagekit/auth')
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return response.data;
      } else {
        logger.error(`Imagekit auth result is not OK: ${response.status}`);
        return null;
      }
    })
    .catch((error) => {
      logger.error(`Imagekit auth error happened: ${error}`);
      return null;
    });
};
