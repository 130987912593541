import { toast } from 'react-toastify';

export const baseNotificationDismissTimeoutMs = 5000;

export const baseNotification = {
  position: toast.POSITION.TOP_RIGHT,
  icon: false,
};

export const baseSuccessNotification = baseNotification;

export const baseErrorNotification = {
  ...baseNotification,
};

export const baseInfoNotification = {
  ...baseNotification,
};
