import { gql } from '@apollo/client';
import { auditedCreativeFragments } from 'graphql/audit/auditedCreativeQueries';

export const importAuditCreativesForAuditTemplate = gql`
  mutation ($templateId: String!) {
    importAuditCreativesForAuditTemplate(templateId: $templateId) {
      ...AuditedCreative
    }
  }
  ${auditedCreativeFragments.auditedCreative}
`;

export const updateAuditedCreative = gql`
  mutation ($input: AuditedCreativeUpdateRequest!) {
    updateAuditedCreative(input: $input) {
      ...AuditedCreative
    }
  }
  ${auditedCreativeFragments.auditedCreative}
`;
