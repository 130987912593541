import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Calendar as CalendarComponent } from 'react-date-range';
import MaskInput from 'react-maskinput';
import { iso8601DateFormat, useLocalisedDayPeriodFormat } from '../../../utils/dateTimeUtils';
import { hasSelection } from '../../../utils/windowUtils';
import { ReactComponent as CalendarIcon } from 'assets/img/calendar.svg';
import { primary } from 'utils/styleUtils';

import styles from './DatePicker.module.scss';

interface Props {
  date?: string;
  onChange: (date: string) => void;
  returnFormat?: string;
  rangeDate?: string;
  readOnly?: boolean;
  minDate?: string;
  maxDate?: string;
  errors?: string[] | JSX.Element[];
  errorsClassName?: string;
  errorItemClassName?: string;
  isInvalid?: boolean;
}

export default function DatePicker({
  onChange,
  date,
  returnFormat,
  readOnly,
  minDate,
  maxDate,
  errors = [],
  errorsClassName = '',
  errorItemClassName = '',
  isInvalid,
}: Props): JSX.Element {
  const withError = isInvalid || errors?.length > 0;

  const [isValid, setIsValid] = useState(true);
  const [isCalendarShown, setIsCalendarShown] = useState(false);
  const datepickerRef = useRef(null);
  const dateFormat = useLocalisedDayPeriodFormat();
  const curDate = useMemo(() => (date ? moment(date, iso8601DateFormat).toDate() : new Date()), [date]);
  const maskString = useMemo(() => dateFormat, [dateFormat]);
  const mask = useMemo(() => maskString.replaceAll(/[DMY]/g, '0'), [maskString]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickOutside = useCallback((event: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (datepickerRef?.current && !datepickerRef?.current?.contains(event.target)) {
      setIsCalendarShown(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const handleClickCalendarIcon = useCallback(() => {
    if (hasSelection() && readOnly) return;
    setIsCalendarShown(!isCalendarShown);
  }, [isCalendarShown, readOnly]);

  const handlePickDate = useCallback(
    (date: Date) => {
      setIsValid(true);
      const value = moment(date).format(iso8601DateFormat);
      onChange(value || '');
      setIsCalendarShown(false);
    },
    [onChange],
  );

  const onValueChange = useCallback(
    (maskedValue: string) => {
      const dateMoment = moment(maskedValue, dateFormat);

      // check that dates changed actually and are correct and are within allowed date range
      if (
        !dateMoment.isValid() ||
        dateMoment.parsingFlags().charsLeftOver !== 0 ||
        dateMoment.isBefore(moment('01/01/2015', 'DD/MM/YYYY'))
      ) {
        setIsValid(false);
        return;
      }
      setIsValid(true);

      onChange(dateMoment.format(returnFormat || dateFormat));
    },
    [dateFormat, onChange, returnFormat],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.datepickerWrapper} ref={datepickerRef}>
        <div
          className={classNames({
            [styles.maskInput]: true,
            [styles.invalid]: !isValid,
          })}
        >
          <MaskInput
            alwaysShowMask={true}
            value={date ? moment(date).format(dateFormat) : ''}
            mask={mask}
            maskString={maskString}
            onValueChange={({ maskedValue }) => onValueChange(maskedValue)}
          />
        </div>
        <div className={styles.icon} onClick={handleClickCalendarIcon}>
          <CalendarIcon />
        </div>
        {isCalendarShown && (
          <div className={styles.calendarWrapper}>
            <CalendarComponent
              // locale={locale}
              date={curDate}
              onChange={handlePickDate}
              minDate={minDate ? moment(minDate).toDate() : moment('2015-01-01').toDate()}
              maxDate={maxDate ? moment(maxDate).toDate() : moment().add(1, 'year').toDate()}
              color={primary}
            />
          </div>
        )}
      </div>

      {withError && (
        <div
          className={classNames({
            [errorsClassName]: true,
            [styles.errors]: true,
          })}
        >
          {errors?.map((error, index) => (
            <div
              key={index}
              className={classNames({
                [errorItemClassName]: true,
                [styles.errorItem]: true,
              })}
            >
              <span
                className={classNames({
                  ['icon-WarningOutline']: true,
                  [styles.errorItemIcon]: true,
                })}
              />
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
