import React from 'react';
import './assets/scss/theme.scss';
import './assets/scss/antdOverwrite.scss';
import './index.css';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { StatusCodes } from 'http-status-codes';
import { ConfigStructure, initBreakpoints } from 'react-match-breakpoints';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, ServerError } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import App from './components/app';
import store from './components/app/store';
import logger from './utils/logger/logger';
import { allPrivateRoutes, LOGIN, URL_QUERY_PARAM_EXPIRED_SESSION } from 'utils/routingUtils';
import './i18n.config';
import { reloadPage } from 'utils/windowUtils';
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'video.js/dist/video-js.css';
import 'videojs-markers/dist/videojs.markers.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const { REACT_APP_GRAPHQL_PATH: uri } = process.env;

const link = new HttpLink();

/**
 * used to control logout action due to possible multiple parallel graphql requests
 */
let logoutTriggered = false;

/**
 * Reacts to a 401 HTTP error from Apollo Client requests and redirects to the Index page on the very first encountered 401
 */
const logoutLink = onError(({ networkError }: ErrorResponse) => {
  try {
    const error = networkError as ServerError;
    const loginLocationWithExpired = `#${LOGIN}?${URL_QUERY_PARAM_EXPIRED_SESSION}`;
    const locationHash = location.hash.substring(2); // remove #/ symbols

    if (error?.statusCode === StatusCodes.UNAUTHORIZED && allPrivateRoutes.includes(locationHash) && !logoutTriggered) {
      logoutTriggered = true;

      // update the history and location and then refresh the page, only in such way normal redirect works
      history.replaceState(null, '', loginLocationWithExpired);
      location.hash = loginLocationWithExpired;
      reloadPage();
    }
  } catch (e) {
    logger.error(`logoutLink error: ${e}`);
  }
});

const client = new ApolloClient({
  uri,
  credentials: 'include',
  link: logoutLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

/**
 * All values should be proper media query strings that could be interpreted by matchMedia method.
 */
const breakpointsConfig: ConfigStructure<string> = {
  mobile: 'screen and (max-width: 575px)', // up to "sm"
  mobileAndTablet: 'screen and (max-width: 991px)', // up to "lg"
  tablet: 'screen and (min-width: 576px) and (max-width: 991px)', // from "sm" to "lg"
  tabletAndDesktop: 'screen and (min-width: 576px)', // from "sm"
  desktop: 'screen and (min-width: 992px)', // from "lg"
  largeDesktop: 'screen and (min-width: 1200px)', // from "xl"
};
const BreakpointsProvider = initBreakpoints(breakpointsConfig);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        {/*
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore */}
        <BreakpointsProvider>
          <div className="app-container">
            <ToastContainer />
            <App />
          </div>
        </BreakpointsProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
