import React from 'react';
import { ReactComponent as RadioIcon } from 'assets/img/radio.svg';
import { ReactComponent as RadioActiveIcon } from 'assets/img/radioActive.svg';
import { AdDnaCtaPlacement } from 'types/businessTypes.d';

import styles from './CtaPlacement.module.scss';

interface Props {
  value: AdDnaCtaPlacement | null;
  onClick: (value: AdDnaCtaPlacement) => void;
}

export default function CtaPlacement({ value, onClick }: Props): JSX.Element {
  return (
    <div className={styles.placement}>
      {[
        AdDnaCtaPlacement.TOP_LEFT,
        AdDnaCtaPlacement.TOP_CENTER,
        AdDnaCtaPlacement.TOP_RIGHT,
        AdDnaCtaPlacement.MIDDLE_LEFT,
        AdDnaCtaPlacement.MIDDLE_CENTER,
        AdDnaCtaPlacement.MIDDLE_RIGHT,
        AdDnaCtaPlacement.BOTTOM_LEFT,
        AdDnaCtaPlacement.BOTTOM_CENTER,
        AdDnaCtaPlacement.BOTTOM_RIGHT,
      ].map((i) => (
        <div key={i} className={styles.item} onClick={() => onClick(i)}>
          {i === value ? <RadioActiveIcon /> : <RadioIcon />}
        </div>
      ))}
    </div>
  );
}
