/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { AUDIT_REPORT } from 'utils/routingUtils';
import { getObjectUrlParams } from 'utils/stringUtils';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToasterInfo from 'components/common/toasterInfo';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { getContactUsMessage } from 'utils/i18nUtils';
import { AuditTemplate } from 'types/audit/auditTemplateTypes';
import { getAuditCreatives, getAuditReport, getAuditTemplate } from 'services/audit.service';
import { AuditReport } from 'types/audit/auditReportTypes';
import { buildAuditUrl } from 'components/features/private/audit/AuditProvider';
import { useMutation, useQuery } from '@apollo/client';
import { getAuditTemplates } from 'graphql/audit/auditTemplateQueries';
import { updateAuditReport } from 'graphql/audit/auditReportMutations';
import logger from 'utils/logger/logger';

export const AuditReportContext = createContext({});

export const filtersInitialState = {
  [SearchParams.TEMPLATE_ID]: '',
};

export const buildAuditReportUrl = (input: { [SearchParams.TEMPLATE_ID]?: string | null }): string => {
  const filters = {
    [SearchParams.TEMPLATE_ID]: input[SearchParams.TEMPLATE_ID] || filtersInitialState[SearchParams.TEMPLATE_ID],
  };

  const urlParams = getObjectUrlParams(filters);

  return `/${AUDIT_REPORT}?${urlParams}`;
};

export interface AuditReportContextProps {
  loading: boolean;
  templates: AuditTemplate[];
  template: AuditTemplate | null;
  report: AuditReport | null;
  creatives: string[];

  onTemplateSelected: (id: string) => void;
  selectedTemplateId: string | null;

  onBackToAudit: () => void;
  onSaveReport: (report: AuditReport) => void;
}

const AuditReportProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    searchParams: { [SearchParams.TEMPLATE_ID]: _templateId = filtersInitialState[SearchParams.TEMPLATE_ID] },
  } = useCustomLocation();

  const {
    data: dataTemplates,
    loading: loadingTemplates,
    error: errorTemplates,
  } = useQuery<{
    getAuditTemplates: AuditTemplate[];
  }>(getAuditTemplates);
  const [doUpdateAuditReport] = useMutation<{ updateAuditReport: boolean }>(updateAuditReport);

  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState<AuditTemplate | null>(null);
  const [report, setReport] = useState<AuditReport | null>(null);
  const [creatives, setCreatives] = useState<string[]>([]);

  const [templates, setTemplates] = useState<AuditTemplate[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(_templateId || null);

  // builds URL search part from the internal state
  const urlParams = useMemo(
    () =>
      getObjectUrlParams({
        [SearchParams.TEMPLATE_ID]: _templateId || filtersInitialState[SearchParams.TEMPLATE_ID],
      }),
    [_templateId],
  );

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);
  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const fetchReportData = useCallback(async () => {
    if (!_templateId?.length) return;

    try {
      const template = await getAuditTemplate(_templateId);
      const report = await getAuditReport(_templateId);
      const creatives = await getAuditCreatives(_templateId);

      if (!template || !report) {
        showErrorNotification();
      } else {
        setTemplate(template);
        setReport(report);
        setCreatives(creatives || []);
      }
    } catch {
      showErrorNotification();
    } finally {
      setLoading(false);
    }
  }, [_templateId, showErrorNotification]);

  const onTemplateSelected = useCallback(
    (id: string) => {
      setSelectedTemplateId(id);
      navigate(
        buildAuditReportUrl({
          [SearchParams.TEMPLATE_ID]: id || '',
        }),
      );
    },
    [navigate],
  );

  const onSaveReport = useCallback(
    (report: AuditReport) => {
      doUpdateAuditReport({
        variables: {
          input: {
            id: report.id,
            templateId: report.templateId,

            firstSecondAnalysis: report.firstSecondAnalysis,
            assetDetails: report.assetDetails,
            visualFeatures: report.visualFeatures,
            copyScript: report.copyScript,
            adConcept: report.adConcept,
            insights: report.insights,
            recommendations: report.recommendations,
          },
        },
      })
        .then((result) => {
          if (result?.data?.updateAuditReport) {
            showSuccessNotification(t('PRIVATE.USER.AUDIT_REPORT.REPORT_UPDATE_SUCCESS'));
          } else {
            logger.error(`Error updating audit report`);
            showErrorNotification();
          }
        })
        .catch((e) => {
          logger.error(`Error updating audit report: ${e}`);
          showErrorNotification();
        });
    },
    [doUpdateAuditReport, showErrorNotification, showSuccessNotification, t],
  );

  const onBackToAudit = useCallback(() => {
    navigate(buildAuditUrl({ [SearchParams.TEMPLATE_ID]: _templateId }));
  }, [_templateId, navigate]);

  // initial data fetch
  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  // set fetched templates
  useEffect(() => {
    if (!loadingTemplates && !errorTemplates) {
      setTemplates(dataTemplates?.getAuditTemplates || []);
    }
  }, [dataTemplates?.getAuditTemplates, errorTemplates, loadingTemplates]);

  useEffect(() => {
    navigate(`/${AUDIT_REPORT}?${urlParams}`, { replace: true });
  }, [navigate, urlParams]);

  const value = {
    loading,
    templates,
    template,
    report,
    creatives,

    onTemplateSelected,
    selectedTemplateId,

    onBackToAudit,
    onSaveReport,
  } as AuditReportContextProps;

  // console.log(value);
  return <AuditReportContext.Provider value={value}>{children}</AuditReportContext.Provider>;
};

export default AuditReportProvider;
