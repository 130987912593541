/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getFullName } from 'utils/userUtils';
import {
  getLocalisedClientPaymentModel,
  getLocalisedCtaPlacement,
  getLocalisedLanguage,
  getLocalisedSocialMediaChannel,
} from 'utils/i18nUtils';
import { AdDna, AdDnaRoleType, AdType, Project } from 'types/businessTypes.d';
import { TFunction } from 'react-i18next';
import { LanguageCode, User } from 'types/types';

export enum AD_DNA_COLUMNS {
  INDEX = 'INDEX',

  CREATED_DATE = 'CREATED_DATE',

  // MAIN
  PGID = 'PGID',
  PGID_AUTHOR = 'PGID_AUTHOR',
  AD_TITLE = 'AD_TITLE',
  PROJECT = 'PROJECT',
  PAYMENT_MODEL = 'PAYMENT_MODEL',
  CHANNEL = 'CHANNEL',
  ASSET_FORMAT = 'ASSET_FORMAT',
  ASSET_RATIO = 'ASSET_RATIO',
  LAYOUT = 'LAYOUT',
  ASSET_FORMAT_TYPE = 'ASSET_FORMAT_TYPE',
  VIDEO_LENGTH = 'VIDEO_LENGTH',
  SLIDES_NUMBER = 'SLIDES_NUMBER',
  ASSET_TYPE = 'ASSET_TYPE',
  ASSET_SUB_TYPE = 'ASSET_SUB_TYPE',
  LANGUAGE = 'LANGUAGE',

  // GRAB ATTENTION
  VISUAL_HOOK_BRANDED = 'VISUAL_HOOK_BRANDED',
  VISUAL_HOOK_FEATURES = 'VISUAL_HOOK_FEATURES',
  VISUAL_HOOK_UNUSUAL = 'VISUAL_HOOK_UNUSUAL',
  VISUAL_HOOK_RELEVANCE = 'VISUAL_HOOK_RELEVANCE',
  COPY_HOOK = 'COPY_HOOK',
  COPY_HOOK_FRAMING = 'COPY_HOOK_FRAMING',
  COPY_HOOK_TYPOLOGY = 'COPY_HOOK_TYPOLOGY',
  COPY_HOOK_RELEVANCE = 'COPY_HOOK_RELEVANCE',
  POPULAR_FORMATS = 'POPULAR_FORMATS',
  REFERENCES = 'REFERENCES',
  TREND = 'TREND',
  SOUND = 'SOUND',
  AI_CONTENT = 'AI_CONTENT',

  // HOLD ATTENTION
  UGC_CREATORS = 'UGC_CREATORS',
  VISUAL_BRANDED = 'VISUAL_BRANDED',
  VISUAL_FEATURES = 'VISUAL_FEATURES',
  COPY = 'COPY',
  SCRIPT = 'SCRIPT',
  PAIN_POINTS = 'PAIN_POINTS',
  PERSONAS = 'PERSONAS',
  BENEFITS = 'BENEFITS',
  PRODUCT_FEATURES = 'PRODUCT_FEATURES',
  PRODUCTS = 'PRODUCTS',
  VALUE_PROPOSITIONS = 'VALUE_PROPOSITIONS',
  EDUCATING = 'EDUCATING',
  EDUCATING_APP = 'EDUCATING_APP',
  EDUCATING_APP_WHOLE_APP = 'EDUCATING_APP_WHOLE_APP',
  EDUCATING_APP_SERVICE = 'EDUCATING_APP_SERVICE',
  EDUCATING_BROAD_TOPIC = 'EDUCATING_BROAD_TOPIC',
  USER_CENTRICITY = 'USER_CENTRICITY',
  SPECIFICITY = 'SPECIFICITY',
  STATISTICS = 'STATISTICS',
  SIMPLE_LANGUAGE = 'SIMPLE_LANGUAGE',
  LEGAL_DISCLAIMER = 'LEGAL_DISCLAIMER',
  SUBTITLES = 'SUBTITLES',
  ENTERTAINING = 'ENTERTAINING',
  ANTICIPATION = 'ANTICIPATION',

  // EVOKE ACTION
  FINANCIAL_BENEFIT = 'FINANCIAL_BENEFIT',
  INSTANT_VALUE = 'INSTANT_VALUE',
  SOCIAL_PROOF = 'SOCIAL_PROOF',
  AUTHORITY = 'AUTHORITY',
  LOSS_AVERSION = 'LOSS_AVERSION',
  SCARCITY = 'SCARCITY',
  UNITY = 'UNITY',
  DEEPER_MOTIVATION_DESIRE = 'DEEPER_MOTIVATION_DESIRE',
  CTA = 'CTA',
  CTA_PLACEMENT = 'CTA_PLACEMENT',

  // ROLES
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  CREATIVE_STRATEGIST = 'CREATIVE_STRATEGIST',
  COPYWRITER = 'COPYWRITER',
  CREATOR = 'CREATOR',
  GRAPHIC_DESIGNER = 'GRAPHIC_DESIGNER',
  MOTION_DESIGNER = 'MOTION_DESIGNER',
  ILLUSTRATOR = 'ILLUSTRATOR',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  VIDEOGRAPHER = 'VIDEOGRAPHER',
  MODEL = 'MODEL',

  // AD SCORE
  AD_SCORE = 'AD_SCORE',
  AD_SCORE_META = 'AD_SCORE_META',
  AD_SCORE_TIKTOK = 'AD_SCORE_TIKTOK',

  // SM INSIGHTS
  INSIGHTS_META_SPEND = 'INSIGHTS_META_SPEND',
  INSIGHTS_META_APP_INSTALL = 'INSIGHTS_META_APP_INSTALL',
  INSIGHTS_META_APP_INSTALL_COST = 'INSIGHTS_META_APP_INSTALL_COST',
  INSIGHTS_META_VIEW_CONTENT = 'INSIGHTS_META_VIEW_CONTENT',
  INSIGHTS_META_VIEW_CONTENT_COST = 'INSIGHTS_META_VIEW_CONTENT_COST',
  INSIGHTS_META_ADD_TO_CART = 'INSIGHTS_META_ADD_TO_CART',
  INSIGHTS_META_ADD_TO_CART_COST = 'INSIGHTS_META_ADD_TO_CART_COST',
  INSIGHTS_META_ADD_TO_WISHLIST = 'INSIGHTS_META_ADD_TO_WISHLIST',
  INSIGHTS_META_ADD_TO_WISHLIST_COST = 'INSIGHTS_META_ADD_TO_WISHLIST_COST',
  INSIGHTS_META_COMPLETE_REGISTRATION = 'INSIGHTS_META_COMPLETE_REGISTRATION',
  INSIGHTS_META_COMPLETE_REGISTRATION_COST = 'INSIGHTS_META_COMPLETE_REGISTRATION_COST',
  INSIGHTS_META_PURCHASE = 'INSIGHTS_META_PURCHASE',
  INSIGHTS_META_PURCHASE_COST = 'INSIGHTS_META_PURCHASE_COST',
  INSIGHTS_META_RETURN_ON_AD_SPEND = 'INSIGHTS_META_RETURN_ON_AD_SPEND',
  INSIGHTS_META_RETURN_ON_AD_SPEND_COST = 'INSIGHTS_META_RETURN_ON_AD_SPEND_COST',

  INSIGHTS_TIKTOK_SPEND = 'INSIGHTS_TIKTOK_SPEND',
  INSIGHTS_TIKTOK_APP_INSTALL = 'INSIGHTS_TIKTOK_APP_INSTALL',
  INSIGHTS_TIKTOK_APP_INSTALL_COST = 'INSIGHTS_TIKTOK_APP_INSTALL_COST',
  INSIGHTS_TIKTOK_VIEW_CONTENT = 'INSIGHTS_TIKTOK_VIEW_CONTENT',
  INSIGHTS_TIKTOK_VIEW_CONTENT_COST = 'INSIGHTS_TIKTOK_VIEW_CONTENT_COST',
  INSIGHTS_TIKTOK_ADD_TO_CART = 'INSIGHTS_TIKTOK_ADD_TO_CART',
  INSIGHTS_TIKTOK_ADD_TO_CART_COST = 'INSIGHTS_TIKTOK_ADD_TO_CART_COST',
  INSIGHTS_TIKTOK_ADD_TO_WISHLIST = 'INSIGHTS_TIKTOK_ADD_TO_WISHLIST',
  INSIGHTS_TIKTOK_ADD_TO_WISHLIST_COST = 'INSIGHTS_TIKTOK_ADD_TO_WISHLIST_COST',
  INSIGHTS_TIKTOK_COMPLETE_REGISTRATION = 'INSIGHTS_TIKTOK_COMPLETE_REGISTRATION',
  INSIGHTS_TIKTOK_COMPLETE_REGISTRATION_COST = 'INSIGHTS_TIKTOK_COMPLETE_REGISTRATION_COST',
  INSIGHTS_TIKTOK_PURCHASE = 'INSIGHTS_TIKTOK_PURCHASE',
  INSIGHTS_TIKTOK_PURCHASE_COST = 'INSIGHTS_TIKTOK_PURCHASE_COST',
}

export const toCsv = (
  items: AdDna[],
  t: TFunction,
  usersById: Map<string, User>,
  projectsById: Map<string, Project>,
  columns: AD_DNA_COLUMNS[],
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { headers: any; data: any[] } => {
  const headers = {};
  Object.values(AD_DNA_COLUMNS)
    .filter((i) => columns.includes(i))
    .forEach((i) => {
      // @ts-ignore
      headers[i] = t(`PRIVATE.USER.ADS_DNA.${i}`);
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any[] = [];
  items.forEach((i) => {
    const item = {};
    // @ts-ignore
    item[AD_DNA_COLUMNS.PGID] = i.pgId;
    // @ts-ignore
    item[AD_DNA_COLUMNS.PGID_AUTHOR] = i.pgIdAuthor?.length ? getFullName(usersById.get(i.pgIdAuthor)) : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.AD_TITLE] = i.title;
    // @ts-ignore
    item[AD_DNA_COLUMNS.PROJECT] = projectsById.get(i.projectId)?.title || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.PAYMENT_MODEL] = getLocalisedClientPaymentModel(i.paymentModel, t);
    // @ts-ignore
    item[AD_DNA_COLUMNS.CHANNEL] = i.channel ? getLocalisedSocialMediaChannel(i.channel, t) : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.ASSET_FORMAT] = i.assetFormat?.replaceAll('_', ' ')?.toLowerCase();
    // @ts-ignore
    item[AD_DNA_COLUMNS.ASSET_RATIO] = i.assetRatio;
    // @ts-ignore
    item[AD_DNA_COLUMNS.LAYOUT] = i.layout?.toLowerCase();
    // @ts-ignore
    item[AD_DNA_COLUMNS.ASSET_FORMAT_TYPE] = i.singleAssetFormatType?.toLowerCase();
    // @ts-ignore
    item[AD_DNA_COLUMNS.VIDEO_LENGTH] = i.assetFormat === AdType.SINGLE_VIDEO ? i.videoLength : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SLIDES_NUMBER] = i.assetFormat === AdType.CAROUSEL ? i.slidesNumber : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.ASSET_TYPE] = i.singleAssetType ? t(`ASSET_TYPE.${i.singleAssetType}`) : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.ASSET_SUB_TYPE] = i.singleAssetSubType ? t(`ASSET_SUB_TYPE.${i.singleAssetSubType}`) : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.LANGUAGE] =
      i.languages?.map((j) => getLocalisedLanguage(j as LanguageCode, t))?.join(', ') || '';

    // *************** GRAB ATTENTION ***************
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_HOOK_BRANDED] = i.visualHookBranded == null ? '' : i.visualHookBranded ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_HOOK_FEATURES] = i.visualHookFeatures
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_HOOK_UNUSUAL] = i.visualHookUnusual == null ? '' : i.visualHookUnusual ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_HOOK_RELEVANCE] = i.visualHookRelevance
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.VISUAL_HOOK_RELEVANCE_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPY_HOOK] = i.copyHookPlain || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPY_HOOK_FRAMING] = i.copyHookFraming
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.COPY_HOOK_FRAMING_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPY_HOOK_TYPOLOGY] = i.copyHookTypology
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.COPY_HOOK_TYPOLOGY_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPY_HOOK_RELEVANCE] = i.copyHookRelevance
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.COPY_HOOK_RELEVANCE_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.POPULAR_FORMATS] = i.popularFormats?.map((i) => t(`PRIVATE.USER.ADS_DNA.${i}`))?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.AI_CONTENT] = i.aiContent == null ? '' : i.aiContent ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.REFERENCES] = !!i.references?.length
      ? t(`PRIVATE.USER.ADS_DNA.REFERENCES_${i.references[0]}`)
      : '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.TREND] = i.trend?.map((i) => t(`PRIVATE.USER.ADS_DNA.TREND_${i}`))?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.SOUND] = i.sound?.map((i) => t(`PRIVATE.USER.ADS_DNA.SOUND_${i}`))?.join(', ');

    // *************** HOLD ATTENTION ***************
    // @ts-ignore
    item[AD_DNA_COLUMNS.UGC_CREATORS] = i.ugcCreators
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.UGC_CREATORS_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_BRANDED] = i.visualBranded == null ? '' : i.visualBranded ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.VISUAL_FEATURES] = i.visualFeatures?.map((i) => t(`PRIVATE.USER.ADS_DNA.${i}`))?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPY] = i.copyPlain || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SCRIPT] = i.scriptPlain || '';
    // @ts-ignore
    item[AD_DNA_COLUMNS.PAIN_POINTS] = i.painPoints?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.PERSONAS] = i.personas?.map((i) => i.name)?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.BENEFITS] = i.benefits?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.PRODUCT_FEATURES] = i.productFeatures?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.PRODUCTS] = i.products?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.VALUE_PROPOSITIONS] = i.valuePropositions?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.EDUCATING] = i.educating == null ? '' : i.educating ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.EDUCATING_APP] = i.educatingApp == null ? '' : i.educatingApp ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.EDUCATING_APP_WHOLE_APP] =
      i.educatingAppWholeApp == null ? '' : i.educatingAppWholeApp ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.EDUCATING_APP_SERVICE] =
      i.educatingAppService == null ? '' : i.educatingAppService ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.EDUCATING_BROAD_TOPIC] =
      i.educatingBroadTopic == null ? '' : i.educatingBroadTopic ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.USER_CENTRICITY] = i.userCentricity == null ? '' : i.userCentricity ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SPECIFICITY] = i.specificity == null ? '' : i.specificity ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.STATISTICS] = i.statistics == null ? '' : i.statistics ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SIMPLE_LANGUAGE] = i.simpleLanguage
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.SIMPLE_LANGUAGE_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.LEGAL_DISCLAIMER] = i.legalDisclaimer == null ? '' : i.legalDisclaimer ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SUBTITLES] = i.subtitles == null ? '' : i.subtitles ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.SUBTITLES] = i.subtitles == null ? '' : i.subtitles ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.ENTERTAINING] = i.entertaining == null ? '' : i.entertaining ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.ANTICIPATION] = i.anticipation == null ? '' : i.anticipation ? 'yes' : 'no';

    // *************** EVOKE ACTION ***************
    // @ts-ignore
    item[AD_DNA_COLUMNS.FINANCIAL_BENEFIT] = i.financialBenefit
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.FINANCIAL_BENEFIT_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSTANT_VALUE] = i.instantValue
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.INSTANT_VALUE_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.SOCIAL_PROOF] = i.socialProof
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.SOCIAL_PROOF_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.AUTHORITY] = i.authority?.map((i) => t(`PRIVATE.USER.ADS_DNA.AUTHORITY_${i}`))?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.LOSS_AVERSION] = i.lossAversion
      ?.map((i) => t(`PRIVATE.USER.ADS_DNA.LOSS_AVERSION_${i}`))
      ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.SCARCITY] = i.scarcity?.map((i) => t(`PRIVATE.USER.ADS_DNA.SCARCITY_${i}`))?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.UNITY] = i.unity == null ? '' : i.unity ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.DEEPER_MOTIVATION_DESIRE] =
      i.deeperMotivationalDesire == null ? '' : i.deeperMotivationalDesire ? 'yes' : 'no';
    // @ts-ignore
    item[AD_DNA_COLUMNS.CTA] = i.ctas?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.CTA_PLACEMENT] = i.ctaPlacement ? getLocalisedCtaPlacement(i.ctaPlacement, t) : '';

    // ROLES
    // @ts-ignore
    item[AD_DNA_COLUMNS.ACCOUNT_MANAGER] =
      i.accountManagerNames ||
      i.accountManagerName ||
      i.roles
        ?.filter(({ role }) => role === AdDnaRoleType.CREATIVE_STRATEGIST)
        ?.map(({ userId }) => getFullName(usersById.get(userId) || null))
        ?.filter((i) => !!i)
        ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.CREATIVE_STRATEGIST] =
      i.creativeStrategistNames ||
      i.creativeStrategistName ||
      i.roles
        ?.filter(({ role }) => role === AdDnaRoleType.CREATIVE_STRATEGIST)
        ?.map(({ userId }) => getFullName(usersById.get(userId) || null))
        ?.filter((i) => !!i)
        ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.COPYWRITER] =
      i.copywriterNames ||
      i.copywriterName ||
      i.roles
        ?.filter(({ role }) => role === AdDnaRoleType.COPYWRITER)
        ?.map(({ userId }) => getFullName(usersById.get(userId) || null))
        ?.filter((i) => !!i)
        ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.CREATOR] =
      i.creatorNames ||
      i.creatorName ||
      i.roles
        ?.filter(({ role }) => role === AdDnaRoleType.CREATOR)
        ?.map(({ userId }) => getFullName(usersById.get(userId) || null))
        ?.filter((i) => !!i)
        ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.GRAPHIC_DESIGNER] =
      i.graphicDesignerNames ||
      i.graphicDesignerName ||
      i.roles
        ?.filter(({ role }) => role === AdDnaRoleType.GRAPHIC_DESIGNER)
        ?.map(({ userId }) => getFullName(usersById.get(userId) || null))
        ?.filter((i) => !!i)
        ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.MOTION_DESIGNER] =
      i.motionDesignerNames ||
      i.motionDesignerName ||
      i.roles
        ?.filter(({ role }) => role === AdDnaRoleType.MOTION_DESIGNER)
        ?.map(({ userId }) => getFullName(usersById.get(userId) || null))
        ?.filter((i) => !!i)
        ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.ILLUSTRATOR] =
      i.illustratorNames ||
      i.illustratorName ||
      i.roles
        ?.filter(({ role }) => role === AdDnaRoleType.ILLUSTRATOR)
        ?.map(({ userId }) => getFullName(usersById.get(userId) || null))
        ?.filter((i) => !!i)
        ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.PHOTOGRAPHER] =
      i.photographerNames ||
      i.photographerName ||
      i.roles
        ?.filter(({ role }) => role === AdDnaRoleType.PHOTOGRAPHER)
        ?.map(({ userId }) => getFullName(usersById.get(userId) || null))
        ?.filter((i) => !!i)
        ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.VIDEOGRAPHER] =
      i.videographerNames ||
      i.videographerName ||
      i.roles
        ?.filter(({ role }) => role === AdDnaRoleType.VIDEOGRAPHER)
        ?.map(({ userId }) => getFullName(usersById.get(userId) || null))
        ?.filter((i) => !!i)
        ?.join(', ');
    // @ts-ignore
    item[AD_DNA_COLUMNS.MODEL] =
      i.modelNames ||
      i.modelName ||
      i.roles
        ?.filter(({ role }) => role === AdDnaRoleType.MODEL)
        ?.map(({ userId }) => getFullName(usersById.get(userId) || null))
        ?.filter((i) => !!i)
        ?.join(', ');

    // @ts-ignore
    item[AD_DNA_COLUMNS.AD_SCORE] = i.adScore?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.AD_SCORE_META] = i.adScoreMeta?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.AD_SCORE_TIKTOK] = i.adScoreTiktok?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_SPEND] = i.insightsMetaSpend;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_APP_INSTALL] = i.insightsMetaAppInstall;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_APP_INSTALL_COST] = i.insightsMetaAppInstallCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_COMPLETE_REGISTRATION] = i.insightsMetaCompleteRegistration;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_COMPLETE_REGISTRATION_COST] = i.insightsMetaCompleteRegistrationCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_VIEW_CONTENT] = i.insightsMetaViewContent;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_META_VIEW_CONTENT_COST] = i.insightsMetaViewContentCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_SPEND] = i.insightsTiktokSpend;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_APP_INSTALL] = i.insightsTiktokAppInstall;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_APP_INSTALL_COST] = i.insightsTiktokAppInstallCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_COMPLETE_REGISTRATION] = i.insightsTiktokCompleteRegistration;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_COMPLETE_REGISTRATION_COST] =
      i.insightsTiktokCompleteRegistrationCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_PURCHASE] = i.insightsTiktokPurchase;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_PURCHASE_COST] = i.insightsTiktokPurchaseCost?.toFixed(2);
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_VIEW_CONTENT] = i.insightsTiktokViewContent;
    // @ts-ignore
    item[AD_DNA_COLUMNS.INSIGHTS_TIKTOK_VIEW_CONTENT_COST] = i.insightsTiktokViewContentCost?.toFixed(2);

    data.push(item);
  });

  return { headers, data };
};
