import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import logger from 'utils/logger/logger';
import { AuditTemplate } from 'types/audit/auditTemplateTypes';
import { AuditReport } from 'types/audit/auditReportTypes';

export const getAuditTemplate = (templateId: string): Promise<AuditTemplate | null> => {
  return axios
    .get(`/api/audit/template/${templateId}`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return response.data;
      } else {
        logger.error(`Audit template not fetched: ${response.status}`);
        return null;
      }
    })
    .catch((error) => {
      logger.error(`Audit template not fetched: ${error}`);
      return null;
    });
};

export const getAuditReport = (templateId: string): Promise<AuditReport | null> => {
  return axios
    .get(`/api/audit/template/${templateId}/report`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return response.data;
      } else {
        logger.error(`Audit report not fetched: ${response.status}`);
        return null;
      }
    })
    .catch((error) => {
      logger.error(`Audit report not fetched: ${error}`);
      return null;
    });
};

export const getAuditCreatives = (templateId: string): Promise<string[] | null> => {
  return axios
    .get(`/api/audit/template/${templateId}/creatives`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return response.data;
      } else {
        logger.error(`Audit creatives not fetched: ${response.status}`);
        return null;
      }
    })
    .catch((error) => {
      logger.error(`Audit creatives not fetched: ${error}`);
      return null;
    });
};
