import { useTranslation } from 'react-i18next';
import moment, { LocaleSpecification } from 'moment';
import logger from './logger/logger';
import { LanguageCode } from 'types/types.d';

export const defaultDateFormat = 'dd.mm.yyyy';
export const iso8601DateFormat = 'YYYY-MM-DD';

/**
 * This is a place to define any Moment lib customisations.
 */
export const useCustomisedMomentLocales = (): void => {
  const { i18n } = useTranslation();

  [LanguageCode.EN].forEach((language) => {
    const data = i18n.getDataByLanguage(language);

    if (data && data.translation) {
      const dateAndTime = JSON.parse(JSON.stringify(data.translation['DATE_AND_TIME']));
      moment.defineLocale(LanguageCode.EN, {
        monthsShort: dateAndTime.MONTHS.SHORT_ALL.split(','),
        months: dateAndTime.MONTHS.LONG_ALL.split(','),
        weekdaysMin: dateAndTime.WEEK_DAYS.MIN_ALL.split(','),
        weekdaysShort: dateAndTime.WEEK_DAYS.SHORT_ALL.split(','),
        weekdays: dateAndTime.WEEK_DAYS.LONG_ALL.split(','),
      } as LocaleSpecification);
    } else {
      logger.error(`${language} i18n is required`);
    }
  });
};

export const useLocalisedMonthPeriodFormat = (): string => {
  const { t } = useTranslation();
  return t('DATE_AND_TIME.MONTH_FORMAT');
};

export const useLocalisedDayPeriodFormat = (): string => {
  const { t } = useTranslation();
  return t('DATE_AND_TIME.DATE_FORMAT');
};
