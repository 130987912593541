import axios from 'axios';
import { URL_QUERY_PARAM_DOMAIN } from 'utils/routingUtils';
import { StatusCodes } from 'http-status-codes';
import logger from 'utils/logger/logger';
import { Whitelabel } from 'types/types';

/**
 * @return whitelabel or null
 */
export const getWhitelabelByDomain = (domain: string): Promise<Whitelabel | null> => {
  return axios
    .get(`/api/whitelabel?${URL_QUERY_PARAM_DOMAIN}=${domain}`)
    .then((response) => {
      if (response.status === StatusCodes.OK) {
        return response.data;
      } else {
        logger.error(`Error fetching whitelabel by ${domain}: ${response.status}`);
        return null;
      }
    })
    .catch((e) => {
      logger.error(`Error fetching whitelabel by ${domain}: ${e}`);
      return null;
    });
};
