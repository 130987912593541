export enum ContentType {
  APPLICATION_EDIFACT = 'application/EDIFACT',
  APPLICATION_EDI_X12 = 'application/EDI-X12',
  APPLICATION_JAVASCRIPT = 'application/javascript',
  APPLICATION_JAVA_ARCHIVE = 'application/java-archive',
  APPLICATION_JSON = 'application/json',
  APPLICATION_LD_JSON = 'application/ld+json',
  APPLICATION_MSWORD = 'application/msword',
  APPLICATION_OCTET_STREAM = 'application/octet-stream',
  APPLICATION_OGG = 'application/ogg',
  APPLICATION_PDF = 'application/pdf',
  APPLICATION_VND_ANDROID_PACKAGE_ARCHIVE = 'application/vnd.android.package-archive',
  APPLICATION_VND_MOZILLA_XUL_XML = 'application/vnd.mozilla.xul+xml',
  APPLICATION_VND_MS_EXCEL = 'application/vnd.ms-excel',
  APPLICATION_VND_MS_POWERPOINT = 'application/vnd.ms-powerpoint',
  APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS = 'application/vnd.oasis.opendocument.graphics',
  APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION = 'application/vnd.oasis.opendocument.presentation',
  APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET = 'application/vnd.oasis.opendocument.spreadsheet',
  APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT = 'application/vnd.oasis.opendocument.text',
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  APPLICATION_XHTML_XML = 'application/xhtml+xml',
  APPLICATION_XML = 'application/xml',
  APPLICATION_X_SHOCKWAVE_FLASH = 'application/x-shockwave-flash',
  APPLICATION_X_WWW_FORM_URLENCODED = 'application/x-www-form-urlencoded',
  APPLICATION_ZIP = 'application/zip',
  AUDIO_MPEG = 'audio/mpeg',
  AUDIO_VND_RN_REALAUDIO = 'audio/vnd.rn-realaudio',
  AUDIO_X_MS_WMA = 'audio/x-ms-wma',
  AUDIO_X_WAV = 'audio/x-wav',
  IMAGE_GIF = 'image/gif',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_JPG = 'image/jpg',
  IMAGE_PNG = 'image/png',
  IMAGE_SVG_XML = 'image/svg+xml',
  IMAGE_WEBP = 'image/webp',
  IMAGE_TIFF = 'image/tiff',
  IMAGE_VND_DJVU = 'image/vnd.djvu',
  IMAGE_VND_MICROSOFT_ICON = 'image/vnd.microsoft.icon',
  IMAGE_X_ICON = 'image/x-icon',
  MULTIPART_ALTERNATIVE = 'multipart/alternative',
  MULTIPART_FORM_DATA = 'multipart/form-data',
  MULTIPART_MIXED = 'multipart/mixed',
  MULTIPART_RELATED = 'multipart/related',
  TEXT_CSS = 'text/css',
  TEXT_CSV = 'text/csv',
  TEXT_HTML = 'text/html',
  TEXT_JAVASCRIPT = 'text/javascript',
  TEXT_PLAIN = 'text/plain',
  TEXT_XML = 'text/xml',
  VIDEO_MOV = 'video/mov',
  VIDEO_MP4 = 'video/mp4',
  VIDEO_MPEG = 'video/mpeg',
  VIDEO_QUICKTIME = 'video/quicktime',
  VIDEO_WEBM = 'video/webm',
  VIDEO_X_FLV = 'video/x-flv',
  VIDEO_X_MSVIDEO = 'video/x-msvideo',
  VIDEO_X_MS_WMV = 'video/x-ms-wmv',
}

export const findContentType = (type: string): ContentType | null => {
  const value = Object.values(ContentType).find((i) => i.toLowerCase().includes(`/${type.toLowerCase()}`));
  return value ? (value as ContentType) : null;
};
