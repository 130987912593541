import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import useQueryParams from 'hooks/useQueryParams';
import { baseErrorNotification } from 'utils/notificationUtils';
import { openInNewTab, reloadPage } from 'utils/windowUtils';
import { toast } from 'react-toastify';
import { containsNumber, hasUpperCase } from 'utils/validationUtils';
import { LOGIN, URL_QUERY_PARAM_KEY } from 'utils/routingUtils';
import {
  firstNameMaxLength,
  getPrivacyPolicy,
  getTermsAndConditions,
  lastNameMaxLength,
  passwordMaxLength,
  passwordMinLength,
} from 'utils/userUtils';
import { getEmailByInvitationCode, register, verifyInvitationCode } from 'services/user.service';
import { login } from 'services/auth.service';
import { Button, Input, Loading, Paragraph } from 'components/common';
import { ReactComponent as OpenEyeIcon } from 'assets/img/openEye.svg';
import { ReactComponent as ClosedEyeIcon } from 'assets/img/closedEye.svg';
import { ReactComponent as Check } from 'assets/img/check.svg';
import { ReactComponent as Close } from 'assets/img/close.svg';
import AuthPageWrap from '../../common/authPageWrap';
import ToasterInfo from 'components/common/toasterInfo';
import { RegisterUser } from 'types/inputTypes';
import { getContactUsMessage, useProductName } from 'utils/i18nUtils';

import styles from './Signup.module.scss';

interface Values {
  firstName: string;
  lastName: string;
  password: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean): any | (() => any) => {
  return Yup.object().shape({
    firstName: validateOnSubmit
      ? Yup.string()
          .max(firstNameMaxLength, t('VALIDATION.FIRST_NAME_MAX_LENGTH', { length: firstNameMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .max(firstNameMaxLength, t('VALIDATION.FIRST_NAME_MAX_LENGTH', { length: firstNameMaxLength }))
          .optional(),
    lastName: validateOnSubmit
      ? Yup.string()
          .max(lastNameMaxLength, t('VALIDATION.LAST_NAME_MAX_LENGTH', { length: lastNameMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .max(lastNameMaxLength, t('VALIDATION.LAST_NAME_MAX_LENGTH', { length: lastNameMaxLength }))
          .optional(),
    password: validateOnSubmit
      ? Yup.string()
          .max(passwordMaxLength, t('VALIDATION.PASSWORD_MAX_LENGTH', { length: passwordMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .max(passwordMaxLength, t('VALIDATION.PASSWORD_MAX_LENGTH', { length: passwordMaxLength }))
          .optional(),
  });
};

export interface LoginLocationState {
  firstName?: string;
  lastName?: string;
  password?: string;
}

const Signup = () => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const productName = useProductName();

  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [invitationCode, setInvitationCode] = useState('');
  const [email, setEmail] = useState('');

  const passwordValidation = useMemo(
    () => ({
      passwordSizeValidation: {
        valid: password.length >= passwordMinLength,
        message: t('VALIDATION.PASSWORD_MIN_CHARACTERS'),
      },
      passwordNumberValidation: {
        valid: containsNumber(password),
        message: t('VALIDATION.PASSWORD_NUMBERS'),
      },
      passwordUpperCaseValidation: {
        valid: hasUpperCase(password),
        message: t('VALIDATION.PASSWORD_UPPERCASE'),
      },
    }),
    [password, t],
  );

  const showErrorNotification = useCallback(
    (title: string) => {
      const message: string = t(getContactUsMessage());
      toast.error(<ToasterInfo type="error" title={title} description={message} />, { ...baseErrorNotification });
    },
    [t],
  );

  /**
   * Verifies invite code
   *  - If it's valid (i.e. not yet used) fetches corresponding email
   *  - otherwise shows a notification
   *  - if no code is provided navigates to Login page - user can't do much here and must not see Reg page at all
   */
  const verifyInvitation = useCallback(async () => {
    const key = queryParams.get(URL_QUERY_PARAM_KEY);
    if (key) {
      const result = await verifyInvitationCode(key);
      if (result) {
        setInvitationCode(key);
        const email = await getEmailByInvitationCode(key);
        if (email) {
          setEmail(email);
        } else {
          navigate(LOGIN);
        }
      } else {
        showErrorNotification(t('PUBLIC.SIGN_UP.NOT_VALID_INVITATION_CODE'));
        navigate(LOGIN);
      }
    } else {
      navigate(LOGIN);
    }
  }, [navigate, queryParams, showErrorNotification, t]);

  /**
   * Tries to log in. If succeeds then refreshes the page, otherwise redirects to the login page.
   */
  const doLogin = useCallback(
    async (password: string): Promise<void> => {
      try {
        const result = await login(email, password, false);
        if (result) {
          // update the page and let user start working
          reloadPage();
        } else {
          showErrorNotification(t('PUBLIC.LOGIN.FAILED'));
          navigate(LOGIN);
        }
      } catch {
        showErrorNotification(t('PUBLIC.LOGIN.FAILED'));
        navigate(LOGIN);
      }
    },
    [email, navigate, showErrorNotification, t],
  );

  /**
   * Tries to register and if succeeds tries to log in
   */
  const handleSubmit = useCallback(
    async (
      values: Values,
      setSubmitting: (isSubmitting: boolean) => void,
      setErrors: (arg: LoginLocationState) => void,
    ): Promise<void> => {
      if (Object.values(passwordValidation).some((validation) => !validation.valid)) {
        setErrors({ password: t('PUBLIC.SIGN_UP.PASSWORD_REQUIREMENTS') });
      } else {
        setSubmitTriggered(true);
        setSubmitting(true);

        const userData = {
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          email,
          invitationCode,
        } as RegisterUser;

        try {
          const res = await register(userData);
          if (res) {
            await doLogin(values.password);
          } else {
            showErrorNotification(t('PUBLIC.SIGN_UP.FAILED'));
            setSubmitting(false);
          }
        } catch {
          showErrorNotification(t('PUBLIC.SIGN_UP.FAILED'));
          setSubmitting(false);
        }
      }
    },
    [passwordValidation, t, email, invitationCode, doLogin, showErrorNotification],
  );

  const handleSubmitForm = useCallback(
    (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) =>
      handleSubmit(values, setSubmitting, setErrors),
    [handleSubmit],
  );

  useEffect(() => {
    verifyInvitation();
  }, [verifyInvitation]);

  return (
    <AuthPageWrap
      linkText={t('PUBLIC.NAVIGATION.LOG_IN')}
      linkHref={LOGIN}
      hintMessage={t('PUBLIC.SIGN_UP.HAVE_ACCOUNT')}
      title={t('PUBLIC.LOGIN.WELCOME', { product: productName })}
      withBottomLine={true}
    >
      <div className={styles.container}>
        {!!email?.length && (
          <Formik
            validationSchema={validationSchema(t, submitTriggered)}
            onSubmit={handleSubmitForm}
            initialValues={{
              firstName: '',
              lastName: '',
              password: '',
            }}
          >
            {({ handleChange, handleBlur, values, errors, isSubmitting }) => (
              <Form noValidate className={styles.content}>
                <div className={styles.invitationEmail}>
                  <Paragraph size="small" className={styles.inputLabel}>
                    {t('PUBLIC.LOGIN.EMAIL')}
                  </Paragraph>
                  <div className={styles.userEmailContainer}>
                    <h1>{email}</h1>
                  </div>
                </div>
                <Input
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={errors.firstName}
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small" className={styles.inputLabel}>
                        {t('PUBLIC.SIGN_UP.FIRST_NAME')} <p>{`(${t('VALIDATION.REQUIRED')})`}</p>
                      </Paragraph>
                    </div>
                  }
                />
                <Input
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={errors.lastName}
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small" className={styles.inputLabel}>
                        {t('PUBLIC.SIGN_UP.LAST_NAME')} <p>{`(${t('VALIDATION.REQUIRED')})`}</p>
                      </Paragraph>
                    </div>
                  }
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  onChange={(e) => {
                    handleChange(e);
                    setPassword(e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.password}
                  error={errors.password}
                  icon={
                    showPassword ? (
                      <OpenEyeIcon onClick={() => setShowPassword(false)} />
                    ) : (
                      <ClosedEyeIcon onClick={() => setShowPassword(true)} />
                    )
                  }
                  alignIcon="right"
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small" className={styles.inputLabel}>
                        {t('PUBLIC.LOGIN.PASSWORD')}
                        <p>{`(${t('VALIDATION.REQUIRED')})`}</p>
                      </Paragraph>
                    </div>
                  }
                />
                <div className={styles.errorList}>
                  {Object.values(passwordValidation).map((validation, index) => (
                    <div className={styles.passwordRequirements} key={index}>
                      {validation.valid ? <Check className={styles.icon} /> : <Close className={styles.icon} />}
                      <Paragraph size="small">{validation.message} </Paragraph>
                    </div>
                  ))}
                </div>
                <div className={styles.agreement}>
                  <Paragraph size="small" className={styles.agreementStatement}>
                    {t('PUBLIC.SIGN_UP.YOU_AGREE')}
                    <span className={styles.terms} onClick={() => openInNewTab(getTermsAndConditions())}>
                      {t('PUBLIC.SIGN_UP.TERMS')}
                    </span>
                    {t('PUBLIC.SIGN_UP.AND')}
                    <span className={styles.terms} onClick={() => openInNewTab(getPrivacyPolicy())}>
                      {' '}
                      {t('PUBLIC.SIGN_UP.PRIVACY_POLICY')}
                    </span>
                  </Paragraph>
                </div>
                <div className={styles.formButton}>
                  <Button
                    className={styles.formButton}
                    type="submit"
                    disabled={
                      !values.firstName ||
                      !values.lastName ||
                      !values.password ||
                      !!errors.firstName ||
                      !!errors.lastName ||
                      !!errors.password ||
                      isSubmitting
                    }
                    onClick={() => setSubmitTriggered(true)}
                  >
                    {isSubmitting ? <Loading /> : t('PUBLIC.NAVIGATION.SIGN_UP')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </AuthPageWrap>
  );
};

export default Signup;
