import { AdEvent } from 'types/businessTypes';

export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  // BigDecimal: any;
  // OffsetDateTime: any;
  UUID: string;
};

export const GRAPHQL_TYPENAME = '__typename';

export interface StringMap {
  [key: string]: string | null;
}

export interface Pair {
  first?: Maybe<Scalars['String']>;
  second?: Maybe<Scalars['String']>;
}

export interface ReferrerLocationState {
  referrer?: string;
}

// these are default values of React Bootstrap Table2, if they are changed consider using "sizePerPageList" property
export const getTablePageSize = (forAdmin: boolean): number[] => (forAdmin ? [10, 50, 100, 500] : [10, 50, 100]);
export const tablePageSize = [10, 50, 100];
export const adminTablePageSize = [10, 50, 100, 500];

export type Paging = {
  __typename?: 'Paging';
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  pageCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum LanguageCode {
  EN = 'en',
  DE = 'de',
  SE = 'se',
  FI = 'fi',
  RU = 'ru',
  ES = 'es',
  FR = 'fr',
  PT = 'pt',
}

export enum CountryCode {
  US = 'us',
  DE = 'de',
}

export enum Market {
  US = 'US',
  EUROPE = 'EUROPE',
}

export enum CurrencyCode {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  CHF = 'CHF',
}

export enum Location {
  UK = 'UK',
  US = 'US',
  EU = 'EU',
  ASIA = 'ASIA',
  GLOBAL = 'GLOBAL',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  ANY = 'ANY',
}

export enum Market {
  US = 'US',
  EUROPE = 'EUROPE',
}

export enum SocialMediaChannel {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  PINTEREST = 'PINTEREST',
  SNAPCHAT = 'SNAPCHAT',
  TIK_TOK = 'TIK_TOK',
}

export type Translation = {
  languageCode: LanguageCode;
  value: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Country>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  countryIsoName?: Maybe<Scalars['String']>;
  countryIsoAlpha2?: Maybe<CountryCode>;
  countryIsoAlpha3?: Maybe<Scalars['String']>;
};

export type AppConfig = {
  imagekitUrl: Scalars['String'];
  imagekitPublicKey: Scalars['String'];
  imagekitAuthEndpoint: Scalars['String'];
};

export enum SecurityRole {
  PG_USER = 'pg_user',
  CLIENT_USER = 'client_user',
  CREATOR = 'creator',
  ADMIN = 'admin',
}

export type Users = {
  items: Array<User>;
  paging: Paging;
};

export type User = {
  id: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  role: SecurityRole;
  emailConfirmed: Scalars['Boolean'];
  newEmail?: Maybe<Scalars['Boolean']>;
  disabled: Scalars['Boolean'];
  invitationCode: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  settings: UserSettings;
  clientId?: Maybe<Scalars['String']>;
  clientTeamRole?: Maybe<ClientTeamRole>;
  whitelabel?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  lastModifiedDate?: Maybe<Scalars['String']>;
};

export type UserSettings = {
  languageIso6391: LanguageCode;
  projectViewLayout: ProjectViewLayout;
};

export enum ProjectViewLayout {
  LIST = 'LIST',
  GRID = 'GRID',
}

export enum UserNotificationType {
  AD_ACTIVITY_UPDATES = 'ACTIVITY_UPDATES',
  AD_REVIEW_REMINDER = 'AD_REVIEW_REMINDER',
  MENTIONS = 'MENTIONS',
  DEADLINE_REMINDER = 'DEADLINE_REMINDER',
}

export type Invitations = {
  items: Array<Invitation>;
  paging: Paging;
};

export type Invitation = {
  code: Scalars['ID'];
  usedDate?: Maybe<Scalars['String']>;
  usedByUserId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Scalars['String'];
  clientId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  whitelabel?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
};

export type Creator = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  createdDate: Scalars['String'];
};

export type PgTeamMember = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Clients = {
  __typename?: 'Users';
  items: Array<Client>;
  paging: Paging;
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['String'];
  name: Scalars['String'];
  paymentModels: Array<ClientPaymentModel>;
  whitelabel?: Maybe<Scalars['String']>;
  slackChannel?: Maybe<Scalars['String']>;
  painPoints?: Maybe<Array<Scalars['String']>>;
  productFeatures?: Maybe<Array<Scalars['String']>>;
  products?: Maybe<Array<Scalars['String']>>;
  benefits?: Maybe<Array<Scalars['String']>>;
  valuePropositions?: Maybe<Array<Scalars['String']>>;
  personas?: Maybe<Array<ClientPersona>>;
  ctas?: Maybe<Array<Scalars['String']>>;

  facebookAdAccountId?: Maybe<Scalars['String']>;
  facebookImportExternalAds?: Maybe<Scalars['Boolean']>;
  facebookAdEvents?: Maybe<Array<AdEvent>>;

  tikTokAdvertiserId?: Maybe<Scalars['String']>;
  tikTokImportExternalAds?: Maybe<Scalars['Boolean']>;
  tikTokAdEvents?: Maybe<Array<AdEvent>>;

  starMetric?: Maybe<ClientStarMetric>;

  createdDate: Scalars['String'];
  lastModifiedDate?: Maybe<Scalars['String']>;
};

export enum ClientPaymentModel {
  STANDARD = 'STANDARD',
  PERFORMANCE = 'PERFORMANCE',
}

export enum ClientTeamRole {
  APPROVER = 'APPROVER',
  REVIEWER = 'REVIEWER',
}

export type ClientPersona = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ClientTeamMember = {
  __typename?: 'ClientTeamMember';
  id: Scalars['String'];
  invitationCode?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  profilePic?: Maybe<Scalars['String']>;
  teamRole: ClientTeamRole;
  createdDate?: Maybe<Scalars['String']>;
};

export enum ClientStarMetric {
  REGISTRATION = 'REGISTRATION',
  PURCHASE = 'PURCHASE',
}

export type UserNotificationSettings = {
  __typename?: 'UserNotificationSettings';
  settings: Array<UserNotificationSetting>;
};

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  email: Scalars['Boolean'];
  slack: Scalars['Boolean'];
  type: Scalars['String'];
};

export type Whitelabels = {
  items: Array<Whitelabel>;
  paging: Paging;
};

export type Whitelabel = {
  id: Scalars['String'];
  domain: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  logoContent?: Maybe<Scalars['String']>;
  logoName?: Maybe<Scalars['String']>;
  faviconContent?: Maybe<Scalars['String']>;
  faviconName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
};
