import React, { useState, createContext, useRef, useEffect } from 'react';

export const TooltipContext = createContext({});

interface TooltipProviderProps {
  children?: React.ReactNode;
}

interface TooltipProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface TooltipContextProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: any;
  tooltipProps: TooltipProps;
  setTooltipProps: (object: TooltipProps | null) => void;
}

export const defaultTooltipProps = {
  effect: 'solid',
  place: 'bottom',
  type: 'dark',
  delayHide: 0,
  delayShow: 0,
};

/**
 * ModalProvider is responsible for connection of multiple Tooltip-related components.
 */

const TooltipProvider = (props: TooltipProviderProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [context, setContext] = useState<TooltipContextProps | null>(null);
  const [tooltipProps, setTooltipProps] = useState<TooltipProps | null>(defaultTooltipProps);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setContext(modalRef?.current);
  }, [modalRef]);

  const value = {
    context,
    setTooltipProps: (props: TooltipProps) => setTooltipProps({ ...defaultTooltipProps, ...props }),
    tooltipProps,
  };

  return (
    <>
      <TooltipContext.Provider value={value}>{props?.children}</TooltipContext.Provider>
      <div ref={modalRef} />
    </>
  );
};

export default TooltipProvider;
