import { gql } from '@apollo/client';
import { auditTemplateFragments } from 'graphql/audit/auditTemplateQueries';

export const createAuditTemplate = gql`
  mutation ($url: String!, $adsNumber: Int!, $name: String!) {
    createAuditTemplate(url: $url, adsNumber: $adsNumber, name: $name) {
      ...AuditTemplate
    }
  }
  ${auditTemplateFragments.auditTemplate}
`;
