import React from 'react';
import { Props } from './type';

import styles from './ToasterInfo.module.scss';

const backgroundColors = {
  error: '#F7188A',
  info: '#4526AD',
  success: '#00FF7F',
};

const ToasterInfo = ({ title, description, type }: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.container_color} style={{ backgroundColor: backgroundColors[type] }} />
      <div className={styles.container_info}>
        {title && <p className={styles.container_info_title}>{title}</p>}
        {description && <p className={styles.container_info_description}>{description}</p>}
      </div>
    </div>
  );
};

export default ToasterInfo;
