import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
const ReactLazyPreload = (importStatement: any): any => {
  const Component = React.lazy(importStatement);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Component.preload = importStatement;
  return Component;
};

export default ReactLazyPreload;
