import React, { useContext, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import { TooltipContext, TooltipContextProps } from 'contextProviders/TooltipProvider';

const TooltipPortal = (): JSX.Element | null => {
  const { context = true, tooltipProps, tooltipProps: { id } = {} } = useContext(TooltipContext) as TooltipContextProps;

  const [noTooltip, setNoTooltip] = useState<boolean>(false);

  const handleEvent = useCallback(() => {
    setNoTooltip(true);
    setTimeout(() => setNoTooltip(false), 0);
  }, [setNoTooltip]);

  window.addEventListener('mousewheel', handleEvent);
  window.addEventListener('mouseup', handleEvent);
  window.addEventListener('mousedown', handleEvent);
  window.addEventListener('keypress', handleEvent);
  window.addEventListener('click', handleEvent);

  useEffect(() => window.removeEventListener('mousewheel', handleEvent), [handleEvent]);
  useEffect(() => window.removeEventListener('mouseup', handleEvent), [handleEvent]);
  useEffect(() => window.removeEventListener('mousedown', handleEvent), [handleEvent]);
  useEffect(() => window.removeEventListener('keypress', handleEvent), [handleEvent]);
  useEffect(() => window.removeEventListener('click', handleEvent), [handleEvent]);

  if (!id || noTooltip) return <></>;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return ReactDOM.createPortal(<ReactTooltip {...tooltipProps} />, context);
};

export default TooltipPortal;
