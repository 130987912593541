import React from 'react';
import clsx from 'classnames';
import { Link } from 'react-router-dom';
import { Props } from './type';

import styles from './CustomLink.module.scss';

const CustomLink = ({ children, href, icon, size = 'default', disabled, className, ...rest }: Props): JSX.Element => {
  const checkboxContainerStyle = clsx(styles.link, {
    [`${className}`]: !!className,
    [styles.withIcon]: !!icon,
    [styles.disabled]: disabled,
    [styles.smallLink]: size === 'small',
    [styles.defaultLink]: size === 'default',
  });

  return (
    <Link to={href} className={checkboxContainerStyle} {...rest}>
      {icon}
      {children}
    </Link>
  );
};

export default CustomLink;
