import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TFunction, useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import useQueryParams from 'hooks/useQueryParams';
import { Input, Button, Paragraph, Loading } from 'components/common';
import { containsNumber, hasUpperCase } from 'utils/validationUtils';
import { passwordMaxLength, passwordMinLength } from 'utils/userUtils';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { LOGIN, URL_QUERY_PARAM_KEY } from 'utils/routingUtils';
import { resetPassword, verifyPasswordResetKey } from 'services/user.service';
import { ReactComponent as ClosedEyeIcon } from 'assets/img/closedEye.svg';
import { ReactComponent as OpenEyeIcon } from 'assets/img/openEye.svg';
import { ReactComponent as Check } from 'assets/img/check.svg';
import { ReactComponent as Close } from 'assets/img/close.svg';
import AuthPageWrap from '../../common/authPageWrap';
import ToasterInfo from 'components/common/toasterInfo';

import styles from './ChangePassword.module.scss';
import { getContactUsMessage } from 'utils/i18nUtils';

interface FormInputs {
  newPassword: Partial<string>;
  password: Partial<string>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean): any | (() => any) => {
  return Yup.object().shape({
    password: validateOnSubmit
      ? Yup.string()
          .max(passwordMaxLength, t('VALIDATION.PASSWORD_MAX_LENGTH', { length: passwordMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .max(passwordMaxLength, t('VALIDATION.PASSWORD_MAX_LENGTH', { length: passwordMaxLength }))
          .optional(),
    newPassword: validateOnSubmit
      ? Yup.string().max(passwordMaxLength, t('VALIDATION.PASSWORD_MAX_LENGTH')).required(t('VALIDATION.REQUIRED'))
      : Yup.string().max(passwordMaxLength, t('VALIDATION.PASSWORD_MAX_LENGTH')).optional(),
  });
};

export interface ErrorInputs {
  newPassword?: string;
  password?: string;
}

const ChangePassword = () => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [key, setKey] = useState('');

  const passwordValidation = useMemo(
    () => ({
      passwordSizeValidation: {
        valid: password.length >= passwordMinLength,
        message: t('VALIDATION.PASSWORD_MIN_CHARACTERS'),
      },
      passwordNumberValidation: {
        valid: containsNumber(password),
        message: t('VALIDATION.PASSWORD_NUMBERS'),
      },
      passwordUpperCaseValidation: {
        valid: hasUpperCase(password),
        message: t('VALIDATION.PASSWORD_UPPERCASE'),
      },
    }),
    [password, t],
  );

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback((message: string) => {
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, []);

  const verifyKey = useCallback(async () => {
    const userKey = queryParams.get(URL_QUERY_PARAM_KEY);
    if (userKey) {
      const result = await verifyPasswordResetKey(userKey);
      if (result) {
        setKey(userKey);
      } else {
        showErrorNotification(t(getContactUsMessage()));
        navigate(LOGIN);
      }
    } else {
      showErrorNotification(t(getContactUsMessage()));
      navigate(LOGIN);
    }
  }, [navigate, queryParams, showErrorNotification, t]);

  const handleSubmit = useCallback(
    async (
      values: FormInputs,
      setSubmitting: (isSubmitting: boolean) => void,
      setErrors: (arg: ErrorInputs) => void,
    ): Promise<void> => {
      if (Object.values(passwordValidation).some((validation) => !validation.valid)) {
        setErrors({ password: t('PUBLIC.SIGN_UP.PASSWORD_REQUIREMENTS') });
      } else if (values.password !== values.newPassword) {
        setErrors({ newPassword: t('VALIDATION.PASSWORDS_MUST_FIT') });
      } else {
        setSubmitTriggered(true);
        setSubmitting(true);

        try {
          const result = await resetPassword(values.password, key);
          if (result) {
            showSuccessNotification(t('PUBLIC.CHANGE_PASSWORD.SUCCESS'));
            navigate(LOGIN);
          } else {
            showErrorNotification(t(getContactUsMessage()));
            setSubmitting(false);
            setErrors({ password: t(getContactUsMessage()) });
          }
        } catch {
          showErrorNotification(t(getContactUsMessage()));
          setSubmitting(false);
        }
      }
    },
    [passwordValidation, t, key, showSuccessNotification, navigate, showErrorNotification],
  );

  const handleSubmitForm = useCallback(
    (values: FormInputs, { setSubmitting, setErrors }: FormikHelpers<FormInputs>) =>
      handleSubmit(values, setSubmitting, setErrors),
    [handleSubmit],
  );

  useEffect(() => {
    verifyKey();
  }, [verifyKey]);

  return !!key.length ? (
    <AuthPageWrap
      linkText={t('PUBLIC.NAVIGATION.LOG_IN')}
      linkHref={LOGIN}
      hintMessage={t('PUBLIC.CHANGE_PASSWORD.CHANGE_YOUR_MIND')}
      title={t('PUBLIC.CHANGE_PASSWORD.TITLE')}
      withBottomLine={true}
    >
      <div className={styles.container}>
        <Formik
          validationSchema={validationSchema(t, submitTriggered)}
          onSubmit={handleSubmitForm}
          initialValues={{
            password: '',
            newPassword: '',
          }}
        >
          {({ handleChange, handleBlur, values, errors, isSubmitting }) => (
            <Form noValidate className={styles.content}>
              <div className={styles.errorList}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  onChange={(e) => {
                    handleChange(e);
                    setPassword(e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.password}
                  error={errors.password}
                  icon={
                    showPassword ? (
                      <OpenEyeIcon onClick={() => setShowPassword(false)} />
                    ) : (
                      <ClosedEyeIcon onClick={() => setShowPassword(true)} />
                    )
                  }
                  alignIcon="right"
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small" className={styles.inputLabel}>
                        {t('PUBLIC.LOGIN.PASSWORD')}
                        <p>{`(${t('VALIDATION.REQUIRED')})`}</p>
                      </Paragraph>
                    </div>
                  }
                />
                {Object.values(passwordValidation).map((validation, index) => (
                  <div className={styles.passwordRequirements} key={index}>
                    {validation.valid ? <Check className={styles.icon} /> : <Close className={styles.icon} />}
                    <Paragraph size="small">{validation.message} </Paragraph>
                  </div>
                ))}
              </div>
              <Input
                type={showNewPassword ? 'text' : 'password'}
                name="newPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                error={errors.newPassword}
                label={
                  <div className={styles.inputLabelContainer}>
                    <Paragraph size="small" className={styles.inputLabel}>
                      {t('PUBLIC.CHANGE_PASSWORD.NEW')} <p>{`(${t('VALIDATION.REQUIRED')})`}</p>
                    </Paragraph>
                  </div>
                }
                alignIcon="right"
                icon={
                  showNewPassword ? (
                    <OpenEyeIcon onClick={() => setShowNewPassword(false)} />
                  ) : (
                    <ClosedEyeIcon onClick={() => setShowNewPassword(true)} />
                  )
                }
              />
              <div className={styles.formButton}>
                <Button
                  className={styles.formButton}
                  type="submit"
                  disabled={
                    !values.newPassword || !values.password || !!errors.newPassword || !!errors.password || isSubmitting
                  }
                  onClick={() => setSubmitTriggered(true)}
                >
                  {isSubmitting ? <Loading /> : t('PUBLIC.CHANGE_PASSWORD.TITLE')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthPageWrap>
  ) : (
    <></>
  );
};

export default ChangePassword;
