import React from 'react';
import clsx from 'classnames';
import { Props } from './type';
import { Spin } from 'antd';
// import { ReactComponent as LoadingIcon } from '../../../assets/img/loading.svg';

import styles from './Loading.module.scss';

const Loading = ({ size = 'default', className, ...rest }: Props): JSX.Element => {
  const containerStyle = clsx(styles.loading, {
    [`${className}`]: !!className,
  });

  // todo animate image
  // return <Spin size={size} className={containerStyle} indicator={<LoadingIcon />} {...rest} />;
  return <Spin size={size} className={containerStyle} {...rest} />;
};

export default Loading;
