import React, { memo, useEffect, useRef } from 'react';

interface OutsideClickProps {
  children: React.ReactNode;
  onOutsideClick: () => void;
}

const OutsideClick = ({ children, onOutsideClick }: OutsideClickProps): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, onOutsideClick]);

  return <div ref={wrapperRef}>{children}</div>;
};

export default memo(OutsideClick);
