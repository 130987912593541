import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ConfigSliceState = {
  imagekitUrl: string;
  imagekitPublicKey: string;
  imagekitAuthEndpoint: string;
};

const initialState: ConfigSliceState = {
  imagekitUrl: '',
  imagekitPublicKey: '',
  imagekitAuthEndpoint: '',
};

const configSlice = createSlice({
  name: 'config',
  initialState: initialState,
  reducers: {
    // sets the whole state, can be changed in future to set fields in separate actions
    configSet(state, action: PayloadAction<ConfigSliceState>) {
      state.imagekitUrl = action.payload.imagekitUrl;
      state.imagekitPublicKey = action.payload.imagekitPublicKey;
      state.imagekitAuthEndpoint = action.payload.imagekitAuthEndpoint;
    },
  },
});

export const { configSet } = configSlice.actions;

export default configSlice.reducer;
