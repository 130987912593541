import { gql } from '@apollo/client';

export const auditTemplateFragments = {
  auditTemplate: gql`
    fragment AuditTemplate on AuditTemplate {
      id
      name
      url
      adsNumber
      os
      appIds
      startDate
      endDate
      countries
      networks
      adTypes
      devices
      placement
    }
  `,
};

export const getAuditTemplates = gql`
  query {
    getAuditTemplates {
      ...AuditTemplate
    }
  }
  ${auditTemplateFragments.auditTemplate}
`;
