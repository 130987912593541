import React from 'react';
import clsx from 'classnames';
import { Props } from './type';

import styles from './RadioButton.module.scss';

const RadioButton = ({ label, disabled, alignLabel = 'left', style, className, ...rest }: Props): JSX.Element => {
  const radioContainerStyle = clsx(styles.radioContainer, {
    [`${className}`]: !!className,
    [styles.disabled]: disabled,
    [styles.radioContainerRight]: alignLabel === 'right',
  });

  return (
    <label style={style} className={radioContainerStyle}>
      <p>{label}</p>
      <div className={styles.container}>
        <input {...rest} type="radio" />
        <span className={styles.checkmark} />
      </div>
    </label>
  );
};

export default RadioButton;
